import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

function Button({
  label,
  className,
  onClick,
  isBlack,
  name,
  id,
  imgClass,
  labelClass,
  isSmall,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const getArrowIcon = () => {
    if (isBlack) {
      return !isHovered ? '/images/arrow-white.png' : '/images/right-arrow.png';
    }
    return !isHovered ? '/images/right-arrow.png' : '/images/arrow-white.png';
  };
  return (
    <button
      name={name}
      id={id}
      type="submit"
      className={classNames(
        'text-brand-black border flex items-center justify-between gap-6 w-auto px-6 lg:px-6 2xl:px-10 py-2 lg:py-[1rem] 2xl:py-[1rem] border-black bg-brand-offWhite text-2xl xl:text-[2.5rem] leading-none font-founders text-center transition duration-300 ease-in-out rounded-full hover:bg-brand-black hover:border-brand-white hover:text-brand-white',
        isBlack
          ? '!bg-brand-black !text-brand-white hover:!text-brand-black hover:!bg-brand-white hover:!border-black'
          : ' ',
        className,
        // isHovered ? 'bg-brand-black text-brand-white border-brand-white' : ' ',
      )}
      style={isSmall ? { fontSize: '1.75rem' } : {}}
      // className={`
      //   'text-brand-black border flex items-center justify-between gap-6 w-auto px-6 lg:px-6 2xl:px-10 py-2 lg:py-[1rem] 2xl:py-[1rem] border-black bg-brand-offWhite text-2xl xl:text-[2.5rem] leading-none font-founders text-center transition duration-300 ease-in-out rounded-full ',
      //   ${className}
      //   ${isHovered && 'bg-brand-black text-brand-white border-brand-white'}
      //   ${
      //     isBlack &&
      //     'bg-brand-black text-brand-white hover:text-brand-black hover:bg-brand-white hover:border-black hover:border'
      //   }
      // `}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={onClick}
    >
      {/* {!isHovered ? label : 'Coming Soon'} */}
      {label ? (
        <p
          className={classNames('pt-2 tracking-tight text-center', labelClass)}
        >
          {label}
        </p>
      ) : null}
      <img className={imgClass} src={getArrowIcon()} alt="arrow-icon" />
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isBlack: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  imgClass: PropTypes.string,
  labelClass: PropTypes.string,
  isSmall: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  onClick: () => {},
  isBlack: false,
  name: '',
  id: '',
  imgClass: 'h-[2.35rem]',
  labelClass: '',
  isSmall: false,
};

export default Button;

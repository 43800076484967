import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';

function GridZoom() {
  const parentRef = useRef(null);

  const [hover, setHover] = useState(false);

  // const { scrollYProgress } = useScroll({ target: parentRef });

  // const imgWidth = useTransform(
  //   scrollYProgress,
  //   [0, 0.5, 1],
  //   ['100%', '240%', '100%'],
  // );
  // const imgHeight = useTransform(
  //   scrollYProgress,
  //   [0, 0.5, 1],
  //   ['100%', '140%', '100%'],
  // );
  // const bgImgFilter = useTransform(
  //   scrollYProgress,
  //   [0, 0.2, 0.8, 1],
  //   ['brightness(1)', 'brightness(0.5)', 'brightness(0.5)', 'brightness(1)'],
  // );

  // const vari = { in: { scale: '100%' }, hov: { scale: '150%' } };

  return (
    <div ref={parentRef} className="hidden md:block w-screen">
      <div className="flex flex-col items-center justify-center h-screen w-screen">
        <h2 className=" font-bradlens text-7xl mb-6 2xl:mb-12">
          the collection
        </h2>
        <div className="grid items-center w-full max-w-2xl h-full lg:max-w-4xl 2xl:max-w-5xl grid-cols-5 gap-4">
          <motion.div
            whileHover={{
              zIndex: '100',
            }}
            className={classNames('relative h-full', hover && 'z-0')}
          >
            <motion.img
              whileHover={{
                minWidth: '240%',
                minHeight: '80%',
                transition: { duration: 0.1 },
              }}
              onHoverStart={e => {
                e.target.style.filter = 'none';
                e.target.style.zIndex = 150;
                e.target.style.boxShadow =
                  '0px 0px 50px 9px rgba(255, 255, 255, 0.47)';

                setHover(true);
              }}
              onHoverEnd={e => {
                e.target.style.filter = '';
                e.target.style.zIndex = 0;
                e.target.style.boxShadow = '';
                setHover(false);
              }}
              className={classNames(
                'min-h-[60%] w-fit object-cover object-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 transition-all duration-300',
                hover && 'filter brightness-50 duration-[1500ms]',
              )}
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/new1.png"
              alt=""
            />
          </motion.div>
          <motion.div
            whileHover={{
              zIndex: '100',
            }}
            className={classNames('relative h-full', hover && 'z-0')}
          >
            <motion.img
              whileHover={{
                minWidth: '240%',
                minHeight: '90%',
                transition: { duration: 0.1 },
              }}
              onHoverStart={e => {
                e.target.style.filter = 'none';
                e.target.style.zIndex = 150;
                e.target.style.boxShadow =
                  '0px 0px 50px 9px rgba(255, 255, 255, 0.47)';
                setHover(true);
              }}
              onHoverEnd={e => {
                e.target.style.filter = '';
                e.target.style.zIndex = 0;
                e.target.style.boxShadow = '';
                setHover(false);
              }}
              className={classNames(
                'min-h-[80%] w-fit object-cover object-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 transition-all duration-300',
                hover && 'filter brightness-50 duration-[1500ms]',
              )}
              px
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/new2.png"
              alt=""
            />
          </motion.div>
          <motion.div
            whileHover={{
              zIndex: '100',
            }}
            className={classNames('relative h-full', hover && 'z-0')}
          >
            <motion.img
              whileHover={{
                minWidth: '240%',
                minHeight: '90%',
                transition: { duration: 0.1 },
              }}
              onHoverStart={e => {
                e.target.style.filter = 'none';
                e.target.style.zIndex = 150;
                e.target.style.boxShadow =
                  '0px 0px 50px 9px rgba(255, 255, 255, 0.47)';
                setHover(true);
              }}
              onHoverEnd={e => {
                e.target.style.filter = '';
                e.target.style.zIndex = 0;
                e.target.style.boxShadow = '';
                setHover(false);
              }}
              className={classNames(
                'min-h-[67%] w-fit object-cover object-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 transition-all duration-300',
                hover && 'filter brightness-50 duration-[1500ms]',
              )}
              px
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/new3.jpg"
              alt=""
            />
          </motion.div>
          <motion.div
            whileHover={{
              zIndex: '100',
            }}
            className={classNames('relative h-full', hover && 'z-0')}
          >
            <motion.img
              whileHover={{
                minWidth: '240%',
                minHeight: '90%',
                transition: { duration: 0.1 },
              }}
              onHoverStart={e => {
                e.target.style.filter = 'none';
                e.target.style.zIndex = 150;
                e.target.style.boxShadow =
                  '0px 0px 50px 9px rgba(255, 255, 255, 0.47)';
                setHover(true);
              }}
              onHoverEnd={e => {
                e.target.style.filter = '';
                e.target.style.zIndex = 0;
                e.target.style.boxShadow = '';
                setHover(false);
              }}
              className={classNames(
                'min-h-[60%] w-fit object-cover object-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 transition-all duration-300',
                hover && 'filter brightness-50 duration-[1500ms]',
              )}
              px
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/new4.jpg"
              alt=""
            />
          </motion.div>
          <motion.div
            whileHover={{
              zIndex: '100',
            }}
            className={classNames('relative h-full', hover && 'z-0')}
          >
            <motion.img
              whileHover={{
                minWidth: '240%',
                minHeight: '90%',
                transition: { duration: 0.1 },
              }}
              onHoverStart={e => {
                e.target.style.filter = 'none';
                e.target.style.zIndex = 150;
                e.target.style.boxShadow =
                  '0px 0px 50px 9px rgba(255, 255, 255, 0.47)';
                setHover(true);
              }}
              onHoverEnd={e => {
                e.target.style.filter = '';
                e.target.style.zIndex = 0;
                e.target.style.boxShadow = '';
                setHover(false);
              }}
              className={classNames(
                'min-h-[78%] w-fit object-cover object-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 transition-all duration-300',
                hover && 'filter brightness-50 duration-[1500ms]',
              )}
              px
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/5.jpg"
              alt=""
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default GridZoom;

import Button from 'components/Button';
import useMediaQuery from 'hooks/useMediaQuery';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function NavItem({
  label,
  link,
  hasExternalLink,
  // getMouseEnter,
  // itemIndex,
  // open,
  setOpen,
  setOpenSubMenu,
  // eslint-disable-next-line no-unused-vars
  openSubMenu,
}) {
  const isBiggerThanSm = useMediaQuery('(min-width: 1080px)');
  const getLink = () => {
    return isBiggerThanSm ? '#' : link;
  };
  return hasExternalLink ? (
    <a
      target="_blank"
      rel="noreferrer"
      href={link}
      className=" max-sm:w-auto block sm:py-2 pl-3 pr-4 rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
    >
      {label}
    </a>
  ) : (
    <div className="relative max-sm:flex max-sm:flex-col max-sm:text-left max-sm:gap-4 md:mt-2">
      {label === 'Collection' && !isBiggerThanSm ? (
        <>
          <Link
            onClick={() => setOpen(false)}
            to="/collection"
            className=" max-sm:break-words py-2 pl-3 pr-4 rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 md:mt-2"
            onMouseEnter={() => {
              setOpenSubMenu(true);
            }}
          >
            Hollywood : from Golden Age to the first Blockbusters
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/models"
            className=" max-sm:break-words py-2 pl-3 pr-4 rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 md:mt-2"
            onMouseEnter={() => {
              setOpenSubMenu(true);
            }}
          >
            Iconic models of the past
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/focus-bloc-pass"
            className=" sm:hidden py-2 mb-4 pl-3 pr-4 h-min rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
          >
            <Button
              className="px-8 text-lg lg:px-8 2xl:px-12 py-2 lg:py-8 min-[1080px]:py-[0.45rem] 2xl:py-[0.5rem]"
              isBlack
              label="Focus Bloc Pass"
            />
          </Link>
        </>
      ) : (
        <Link
          onClick={() => setOpen(false)}
          to={label === 'Collection' ? getLink() : link}
          className=" max-sm:w-max py-2 pl-3 pr-4 rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 md:mt-2"
          onMouseEnter={() => {
            setOpenSubMenu(true);
          }}
        >
          {label}
        </Link>
      )}
      {openSubMenu ? (
        <div className="absolute bg-black h-2 w-2 left-1/2 -bottom-[0.2rem] -translate-x-1/2" />
      ) : null}
    </div>
  );
}

NavItem.defaultProps = {
  hasExternalLink: false,
  // getMouseEnter: () => {},
  // itemIndex: 0,
  // open: false,
  setOpen: () => {},
  setOpenSubMenu: () => {},
  openSubMenu: false,
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  hasExternalLink: PropTypes.bool,
  // getMouseEnter: PropTypes.func,
  // itemIndex: PropTypes.number,
  // open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSubMenu: PropTypes.func,
  openSubMenu: PropTypes.bool,
};

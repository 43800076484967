import PropTypes from 'prop-types';

function Avatar({ imageLink }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        alt="profileImage"
        className="w-28 h-28 rounded-full object-cover ring-offset-4 dark:bg-gray-500 ring-violet-400 ring-offset-gray-800"
        src={imageLink}
      />
    </div>
  );
}

Avatar.propTypes = {
  imageLink: PropTypes.string.isRequired,
};

export default Avatar;

import { navItemsLeft } from 'content/navigation';
import NavItem from 'components/layout/NavItem';
import NavItemsExternal from 'components/layout/NavItemsExternal';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'hooks/useMediaQuery';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/Button';

function Header() {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  // const { pathname } = useLocation();

  const headerRef = useRef();

  useEffect(() => {
    setOpen(false);

    const header = headerRef.current;
    const sticky = header.offsetTop;

    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add('border-b-2', 'border-black');
      } else {
        header.classList.remove('border-b-2', 'border-black');
      }
    };
  }, [headerRef]);
  return (
    <div className="fixed z-10 ">
      {/* <div
        className={classNames(
          'w-screen bg-[#F2F2F2] pb-1 pt-3 px-4 max-sm:text-sm border border-b-brand-black border-solid text-center text-black font-bold',
          open && 'hidden',
        )}
      >
        The mint of our Hollywood collection is on May 2nd, 6.30pm CET /12.30pm
        EST
      </div> */}
      <header
        className={classNames(
          'h-auto bg-[#F2F2F2] py-6 px-0 flex items-center w-screen  min-[1080px]:px-16 text-2xl md:text-3xl lg:text-xl font-founders font-semibold text-brand-black transition-all duration-50 ease-in',
          open && 'static',
        )}
        ref={node => {
          headerRef.current = node;
        }}
      >
        <div className="flex justify-between lg:p-4 min-[1080px]:hidden w-full">
          <Link to="/" className="flex items-center">
            <img
              src="/images/logo-black.png"
              className="max-sm: max-[1080px]:pl-8 h-[3.5rem] min-[1080px]:h-16 min-[1080px]:-mb-0 "
              alt="focus bloc logo"
            />
          </Link>

          <button
            type="button"
            onClick={() => {
              setOpen(state => !state);
            }}
            className="z-20 pr-8"
          >
            <img
              className="h-[2.5rem]"
              alt="menu icon"
              src={open ? '/images/cross.png' : '/images/menu.png'}
            />
          </button>
        </div>
        <div className="w-full hidden min-[1080px]:flex flex-wrap items-center justify-between">
          <NavItemsLeft
            openSubMenu={openSubMenu}
            setOpenSubMenu={setOpenSubMenu}
          />
          <div className="hidden md:block">
            <NavItemsExternal />
          </div>
        </div>
        <div
          className={classNames(
            'absolute max-sm:px-8 w-screen m-0 top-0 right-0 bg-[#F2F2F2] overflow-hidden z-100 flex flex-col text-center gap-4 sm:gap-4 min-[1080px]:items-center sm:justify-center transition-all duration-300 ease-in-out',
            open ? 'h-screen max-sm:py-8' : 'h-0 max-sm:py-0',
          )}
        >
          <NavItemsLeft open={open} setOpen={setOpen} />
          <NavItemsExternal open={open} setOpen={setOpen} />
        </div>
      </header>
      {/* w-full h-64 fixed bg-red-300 */}
      <div
        className={classNames(
          'w-full h-auto fixed  px-8 bg-[#F2F2F2] -mt-1  z-50',
          openSubMenu ? 'block' : 'hidden',
        )}
        onMouseLeave={() => setOpenSubMenu(false)}
      >
        <div className="border-t-2 border-b-2 border-black grid grid-cols-12 gap-12 px-8">
          <Link className="col-span-7" to="/collection">
            <div className="h-full w-min xl:w-full py-10 flex gap-10 ">
              <img
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/homemenu1.png"
                className="w-auto lg:max-h-[250px] object-cover"
                alt=""
              />
              <div className=" flex flex-col justify-evenly">
                <h2 className="font-bradlens text-[1.25rem] sm:text-[1.5rem] 2xl:text-[2rem] tracking-normal 2xl:tracking-wide font-semibold leading-[1.1] ">
                  Hollywood: from golden age to the first Blockbusters.
                </h2>
                <p className="text-[1.5rem] sm:text-[1.2rem] leading-[1.2] tracking-normal 2xl:text-[1.75rem]">
                  The collection of 333 photos is exclusively coming from the
                  recovered archives of Movie Star News, featuring Hollywood’s
                  most prominent movie stills and icons.
                </p>
                <Link to="/collection">
                  <Button
                    label="Discover"
                    className="w-max px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl bg-transparent hover:bg-brand-black"
                  />
                </Link>
              </div>
            </div>
          </Link>
          <Link to="/models" className="col-span-5">
            <div className="h-full w-min xl:w-full py-10 flex gap-10 c">
              <img
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-4.jpg"
                className=" max-h-[250px]"
                alt=""
              />
              <div className=" flex flex-col justify-evenly">
                <h2 className=" font-adelia text-[1.25rem] sm:text-[1.65rem] 2xl:text-[2rem]  2xl:tracking-wide tracking-normal font-normal leading-[1.1] ">
                  iconic models of the past
                </h2>
                <p className="text-[1.5rem] sm:text-[1.2rem] leading-[1.2] tracking-normal 2xl:text-[1.75rem]">
                  Focus Bloc and Paris Match have collaborated for their first
                  collection, curating 120 iconic photos featuring 23
                  celebrities.
                </p>
                <Link to="/models">
                  <Button
                    label="Discover"
                    className="w-max px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl bg-transparent hover:bg-brand-black"
                  />
                </Link>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

function NavItemsLeft({ open, setOpen, setOpenSubMenu, openSubMenu }) {
  const isMd = useMediaQuery('(max-width: 1080px)');
  return (
    <div className="flex max-sm:w-auto flex-col min-[1080px]:flex-row sm:items-center gap-2 md:gap-2 lg:gap-4 xl:gap-6">
      <Link
        to="/"
        className={classNames(
          'flex items-center max-md:pb-[10%]',
          !open && 'pr-4',
        )}
      >
        {isMd ? (
          <img
            src="/images/logo-black.png"
            className=" h-[3.5rem] pl-4 -mt-2"
            alt="focus bloc logo"
          />
        ) : (
          <img
            src="/images/logo-black.png"
            className="h-[3.5rem]"
            alt="focus bloc logo"
          />
        )}
      </Link>
      {navItemsLeft?.map(item => {
        return (
          <NavItem
            setOpenSubMenu={
              item.label === 'Collection' ? setOpenSubMenu : () => {}
            }
            openSubMenu={item.label === 'Collection' ? openSubMenu : false}
            open={open}
            setOpen={setOpen}
            key={item.label}
            label={item.label}
            link={item.link}
          />
        );
      })}
    </div>
  );
}

NavItemsLeft.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSubMenu: PropTypes.func,
  openSubMenu: PropTypes.bool,
};

NavItemsLeft.defaultProps = {
  open: false,
  setOpen: () => {},
  setOpenSubMenu: () => {},
  openSubMenu: false,
};

export default Header;

import Button from 'components/Button';
import PropTypes from 'prop-types';
import { useState } from 'react';

function RegisterPage() {
  const [ethView, setEthView] = useState(false);
  return (
    <div>
      <div className="w-screen fixed z-50 bg-[#F2F2F2] pb-1 pt-3 px-4 max-sm:text-sm border border-b-brand-black border-solid text-center text-black font-bold">
        The mint of our Hollywood collection is on May 2nd, 6.30pm CET /12.30pm
        EST
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 max-[1080px]:pt-[42px] sm:h-screen w-screen">
        <div
          className="h-full w-full bg-cover bg-center"
          style={{
            backgroundImage: `url(https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-reg.png)`,
          }}
        >
          <div className=" max-sm:py-12  max-md:py-6 w-screen min-h-min sm:h-screen md:w-full md:h-full text-brand-white backdrop-brightness-[0.35] text-center flex max-md:flex-col items-center">
            <div className="w-full">
              <p className="font-bradlens text-[3.25rem] md:text-[5rem] leading-none">
                hollywood
              </p>
              <p className="font-bradlens text-[1.5rem] md:text-3xl mt-2 md:mt-4 md:mb-8 tracking-normal leading-none">
                from Golden Age to the first Blockbusters
              </p>
              <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-normal 2xl:text-[1.75rem] sm:px-28 max-sm:my-6 max-md:my-4">
                333 photos featuring the most recognised movies and icons of
                Hollywood.
              </p>
            </div>
            <p className="md:hidden font-bradlens text-[1.5rem] md:text-3xl tracking-normal leading-none h-min mt-10 mb-8 sm:mt-6 sm:mb-4 max-sm:px-4">
              {ethView
                ? 'Register here to GET A chance to buy one of the 333 photographs'
                : 'Register here to stay up to date about the allowlist process'}
            </p>
            <div className="md:hidden w-full h-full px-4 sm:px-8 text-brand-white md:text-brand-black text-center flex justify-center flex-wrap content-around">
              {ethView ? (
                <RegisterEthSm />
              ) : (
                <RegisterFormSm setEthView={setEthView} />
              )}
            </div>
          </div>
        </div>
        {ethView ? (
          <RegisterETHLg />
        ) : (
          <RegisterFormLg setEthView={setEthView} />
        )}
      </div>
    </div>
  );
}

function InputField({ label, name, type, id, required }) {
  return (
    <label htmlFor="mce-EMAIL" className="h-12 md:h-[3.4rem] w-full">
      {/* Email Address <span className="asterisk">*</span> */}
      <p className="max-sm:tracking-normal pl-2 leading-none text-left text-[1.25rem] sm:text-[1.35rem] md:text-[1.5rem] font-semibold max-md:text-brand-white">
        {label}
      </p>
      <input
        required={required}
        type={type}
        name={name}
        className=" text-left w-full md:h-full tracking-tight max-sm:py-2 bg-transparent border border-brand-white md:border-brand-black sm:tracking-wider text-[1.5rem] sm:text-2xl 2xl:text-3xl block flex-grow-0 max-md:text-center md:pl-8 font-founders rounded-full"
        id={id}
      />
    </label>
  );
}

function RegisterFormLg({ setEthView }) {
  return (
    <form
      action="https://focusbloc.us21.list-manage.com/subscribe/post?u=2d4545a41ff669f4ddca3f53c&amp;id=a7ddc615f7&amp;f_id=00f990e1f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_self"
    >
      <div className="max-md:hidden w-full h-full px-8 md:px-20 text-brand-black text-center flex justify-center flex-wrap content-around">
        <p className="font-bradlens text-3xl mt-8 mb-2 px-1 tracking-tight leading-none h-min text-left">
          Register here to stay up to date about the allowlist process
        </p>
        <InputField
          label="Enter your email"
          name="EMAIL"
          id="mce-EMAIL"
          required
          type="email"
        />

        <InputField
          label="Enter your @twitter"
          type="text"
          value=""
          name="MMERGE6"
          id="mce-MMERGE6"
          required
        />
        <div className="h-12 md:h-[3.4rem] w-full">
          <InputField
            required
            label="Enter your ETH address"
            name="MMERGE5"
            type="text"
            id="mce-MMERGE5"
          />
          <p className="mt-3 p-0 pl-2 text-left font-bold tracking-wide 2xl:text-lg 2xl:mt-4">
            Don’t have an ETH address yet,{' '}
            <span className="hover:cursor-pointer">
              <button
                className="underline decoration-2"
                type="button"
                onClick={() => {
                  setEthView(true);
                }}
              >
                register here
              </button>
            </span>
          </p>
        </div>

        <div
          className="response"
          id="mce-error-response"
          style={{ display: 'none' }}
        />
        <div
          className="response"
          id="mce-success-response"
          style={{ display: 'none' }}
        />
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_2d4545a41ff669f4ddca3f53c_a7ddc615f7"
            tabIndex={-1}
            defaultValue=""
          />
        </div>
        <Button
          isSmall
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          isBlack
          label="Register"
          className="w-full px-6 lg:px-12 2xl:px-16 my-4 text-lg xl:text-[1.85rem] 2xl:text-3xl py-[0.5rem] lg:py-[0.5rem] 2xl:py-[0.5rem]  "
        />
      </div>
    </form>
  );
}

function RegisterFormSm({ setEthView }) {
  return (
    <form
      action="https://focusbloc.us21.list-manage.com/subscribe/post?u=2d4545a41ff669f4ddca3f53c&amp;id=a7ddc615f7&amp;f_id=00f990e1f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_self"
      className=" w-full sm:px-16 grid grid-cols-1 max-sm:gap-16 justify-between content-between h-full text-left"
    >
      <InputField
        label="Enter your email"
        name="EMAIL"
        id="mce-EMAIL"
        required
        type="email"
      />
      <InputField
        label="Enter your @twitter"
        type="text"
        value=""
        name="MMERGE6"
        id="mce-MMERGE6"
        required
      />
      <div className="h-12 md:h-[3.4rem] w-full">
        <InputField
          required
          label="Enter your ETH address"
          name="MMERGE5"
          type="text"
          id="mce-MMERGE5"
        />

        <p className="mt-3 p-0 pl-2 text-left font-bold tracking-wide">
          Don’t have an ETH address yet,{' '}
          <span className="underline hover:cursor-pointer">
            <button
              className="underline decoration-2"
              type="button"
              onClick={() => {
                setEthView(true);
              }}
            >
              register here
            </button>
          </span>
        </p>
      </div>
      <Button
        label="Register"
        className="w-full px-6 max-md:h-16 lg:px-16 2xl:px-20 my-4 md:py-4 xl:text-3xl 2xl:text-4xl"
      />
    </form>
  );
}

function RegisterETHLg() {
  return (
    <form
      action="https://focusbloc.us21.list-manage.com/subscribe/post?u=2d4545a41ff669f4ddca3f53c&amp;id=c3e5fdb58b&amp;f_id=007e91e1f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_self"
    >
      <div id="mc_embed_signup_scroll" />
      <div className="max-md:hidden w-full h-full px-8 md:px-20 text-brand-black text-center flex justify-center flex-wrap content-around">
        <p className="font-bradlens text-3xl mt-8 mb-2 px-1 tracking-tight leading-none h-min text-left">
          Register here to GET A chance to buy one of the 333 photographs
        </p>
        <InputField
          name="FNAME"
          id="mce-FNAME"
          label="Enter your first name and last name"
          required
          type="text"
        />

        <InputField
          name="EMAIL"
          id="mce-EMAIL"
          label="Enter your email"
          type="email"
          value=""
          required
        />

        <InputField
          type="text"
          name="PHONE"
          id="mce-PHONE"
          label="Enter your phone number (optional)"
        />

        <p className="mt-4 p-0 pl-2 text-left font-bold tracking-wide leading-none text-[1rem] sm:text-[1.1rem] md:text-[1.2rem] max-md:text-brand-white">
          We will get in touch shortly to guide you in the creation of your
          wallet and the mint of our collection. For additional information you
          may contact us at contact@focusbloc.io
        </p>

        <div
          className="response"
          id="mce-error-response"
          style={{ display: 'none' }}
        />
        <div
          className="response"
          id="mce-success-response"
          style={{ display: 'none' }}
        />
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_2d4545a41ff669f4ddca3f53c_a7ddc615f7"
            tabIndex={-1}
            defaultValue=""
          />
        </div>
        <Button
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          isBlack
          label="Register"
          className="w-full px-6 lg:px-12 2xl:px-16 my-4 text-lg xl:text-2xl 2xl:text-3xl py-[0.5rem] lg:py-[0.5rem] 2xl:py-[0.5rem] xl:text-xl"
        />
      </div>
    </form>
  );
}

function RegisterEthSm() {
  return (
    <form
      action="https://focusbloc.us21.list-manage.com/subscribe/post?u=2d4545a41ff669f4ddca3f53c&amp;id=c3e5fdb58b&amp;f_id=007e91e1f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_self"
    >
      <div className=" w-full sm:px-16 grid grid-cols-1 max-sm:gap-16 justify-between content-between h-full text-left">
        <InputField
          name="FNAME"
          id="mce-FNAME"
          label="Enter your first name and last name"
          required
          type="text"
        />

        <InputField
          name="EMAIL"
          id="mce-EMAIL"
          label="Enter your email"
          type="email"
          value=""
          required
        />

        <InputField
          type="text"
          name="PHONE"
          id="mce-PHONE"
          label="Enter your phone number (optional)"
        />
        <p className="mt-4 p-0 text-left font-bold tracking-wide leading-none text-[1rem] sm:text-[1.1rem] md:text-[1.2rem] max-md:text-brand-white">
          We will get in touch shortly to guide you in the creation of your
          wallet and the mint of our collection. For additional information you
          may contact us at contact@focusbloc.io
        </p>
        <div
          className="response"
          id="mce-error-response"
          style={{ display: 'none' }}
        />
        <div
          className="response"
          id="mce-success-response"
          style={{ display: 'none' }}
        />
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_2d4545a41ff669f4ddca3f53c_a7ddc615f7"
            tabIndex={-1}
            defaultValue=""
          />
        </div>
        <Button
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          label="Register"
          className="w-full px-6 max-md:h-16 lg:px-16 2xl:px-20 my-4 md:py-4 xl:text-3xl 2xl:text-4xl max-sm:-mt-8 xl:text-xl px-6 text-xl xl:text-xl"
        />
      </div>
    </form>
  );
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

RegisterFormLg.propTypes = {
  setEthView: PropTypes.func.isRequired,
};
RegisterFormSm.propTypes = {
  setEthView: PropTypes.func.isRequired,
};

export default RegisterPage;

import { photosIntroContent } from 'content/home';
import useTextAnimation from 'hooks/useTextAnimation';
import { Link } from 'react-router-dom';

function PhotographsInfo() {
  useTextAnimation('photographs-info');
  useTextAnimation('photographs-info2');
  useTextAnimation('photographs-info3');
  useTextAnimation('faq-link');
  useTextAnimation('photographs-info4');
  useTextAnimation('see-text');
  useTextAnimation('bracket-text');

  return (
    <div className="flex justify-end">
      <div
        className=" lg:w-1/2 text-[2rem] md:text-[3.4rem] font-semibold px-4 md:px-8 lg:pr-20 xl:pr-40 font-founders leading-[0.9] max-md:tracking-normal md:leading-none"
        // id="photographs-info"
      >
        <div id="photographs-info">{photosIntroContent?.line1}</div>
        <div id="photographs-info2" className="my-12">
          {photosIntroContent?.line2}
          <Link to="/focus-bloc-pass" className="underline">
            Focus Bloc Pass
          </Link>
        </div>
        <div id="photographs-info3">
          {photosIntroContent?.line3}
          <a className=" underline" href="#faq">
            FAQs
          </a>
          <span> )</span>{' '}
        </div>

        {/* <a className=" underline pl-3" href="#faq" id="faq-link">
          (See FAQs)
        </a>
        {/* <div id="test">
          {photosIntroContent?.line1}
          {photosIntroContent?.line1}
          {photosIntroContent?.line1}
        </div> */}
        {/* {photosIntroContent?.line2} <br />
        {photosIntroContent?.line3} (see FAQs) */}
        {/* <span className="font-normal">
          (see
          <a className=" underline underline-offset-8 decoration-2" href="#faq">
            FAQs
          </a>
          )
        </span> */}
        {/* <div className="sticky bottom-0 bg-black h-24 -mt-2 w-full opacity-60" /> */}
      </div>
    </div>
  );
}

export default PhotographsInfo;

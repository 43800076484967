import { useParallax } from 'react-scroll-parallax';

function FocusBlocPassWrapper() {
  const parallax = useParallax({
    scale: [1, 0.5, 'easeInQuad'],
  });

  return (
    <div className=" min-w-min sm:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto">
      <div className="aspect-[2/1] stripes p-16 max-sm:p-8 shadow-[0px_9px_15px_4px_rgb(255_255_255_/_50%)] border-2 rounded-3xl flex-nowrap flex flex-row items-center gap-8 [@media(min-width:380px)]:gap-12 [@media(min-width:1024px)]:gap-14">
        <img
          ref={parallax.ref}
          src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/brand.png"
          alt="sd"
          className=" shadow-[0px_0px_20px_7px_rgb(255_255_255_/_40%)] max-sm:rounded-tl-[2rem] max-sm:rounded-br-[2rem] rounded-tl-[60px] rounded-br-[60px] w-1/2"
        />
        <p className="font-adelia max-sm:text-[6vw] text-5xl lg:text-6xl xl:text-[74px] w-auto leading-tight lg:leading-tight -mt-2 lg:mt-4 xl:mt-0">
          FOCUS BLOC PASS
        </p>
      </div>
      <div className="mt-3 h-14 border-t-2 border-l-2 border-2 rounded-t-3xl border-b-0 opacity-60" />
    </div>
  );
}

export default FocusBlocPassWrapper;

import FaqSection from 'components/faqs';
import Hero from 'pages/home/Hero';
import NftInfo from 'pages/home/NftInfo';
import PhotographsInfo from 'pages/home/PhotographsInfo';
import PhotographerInfo from 'pages/home/PhotographerInfo';
import Carousel from 'pages/home/Carousel';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
// import { mintDateContent } from 'content/home';
import Button from 'components/Button';

function Home() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  // useEffect(() => {
  //   const drawerTimer = setTimeout(() => {
  //     setOpenDrawer(true);
  //   }, 3000);

  //   return () => {
  //     clearTimeout(drawerTimer);
  //   };
  // }, []);
  return (
    <div className="bg-brand-black text-brand-white pt-44">
      <Hero />
      <div className="px-4">
        {/* <p className="text-center text-[2rem] md:text-[3.5rem] lg:text-[3.75rem] font-semibold font-founders leading-[0.9] max-md:tracking-normal pt-32">
          {mintDateContent.line1}
          <sup>th</sup> {mintDateContent.line2}
        </p> */}
        <div className="pt-8">
          <NftInfo />
        </div>
        <div className="py-16 md:py-64">
          <PhotographsInfo />
        </div>
      </div>
      <div id="collection" className="max-sm:mb-32 max-md:mb-20">
        <Carousel />
      </div>
      <div className="mt-12 px-4">
        <a
          href="https://opensea.io/collection/focus-bloc-iconic-models-of-the-past"
          rel="noreferrer"
          target="_blank"
        >
          <Button
            className=" mx-auto w-auto my-4 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl"
            label="View the collection on Opensea"
          />
        </a>
      </div>
      <div className="px-4">
        <div className="py-20 md:py-64">
          <PhotographerInfo />
        </div>
        <div id="faq">
          <FaqSection />
        </div>
      </div>
      {/* uncomment below code to display a button to manually open the drawer */}
      {/* <button
        type="button"
        onClick={() => {
          setOpenDrawer(true);
        }}
      >
        Toggle Drawer
      </button> */}
      {/* <Drawer open={openDrawer} setOpen={setOpenDrawer} /> */}
    </div>
  );
}

export default Home;

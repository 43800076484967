function MovieList() {
  return (
    <section className="mb-16 ">
      <div className="mb-12 sm:mb-8 text-center">
        <h2 className="text-heading mb-1 text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2.5rem]">
          The 333 photos will feature the following movies and icons of the film
          culture
        </h2>
        <p className="text-description text-[1.35rem] sm:text-[1.65rem] tracking-tight 2xl:text-[1.8rem]">
          The mint will be operated on the Ethereum blockchain as a blind mint
        </p>
      </div>
      <h2 className="text-heading mb-3 text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2.5rem]">
        Movies
      </h2>
      <div className="flex flex-wrap -m-4 mb-4 text-description text-lg sm:text-[1.25rem] xl:text-[1.55rem] tracking-tight 2xl:text-[2.1rem] whitespace-nowrap">
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>Casablanca</li>
            <li>Citizen Kane</li>
            <li>The great escape</li>
            <li>The wizard of OZ</li>
            <li>Lawrence of Arabia</li>
            <li>Gone with the wind</li>
            <li>The wild one</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>James Bond</li>
            <li>Scarface</li>
            <li>The godfather</li>
            <li>Some like it hot</li>
            <li>Easy rider</li>
            <li>The Golddiggers</li>
            <li>Tarzan</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            {/* <li>Star Wars</li> */}
            <li>Full metal jacket</li>
            <li>The kid</li>
            <li>Ten commandments</li>
            <li>7 samourais</li>
            <li>Psycho</li>
            <li>Breakfast at Tiffany’s</li>
            <li>The lady from Shanghai</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>The grapes of wrath</li>
            <li>Dr Strangelove</li>
            <li>King Kong</li>
            <li>12 Angry men</li>
            <li>How to marry a millionaire</li>
            <li>Giant</li>
          </ul>
        </div>
      </div>
      <h2 className="text-heading mb-3 text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2.5rem]">
        Celebrities
      </h2>
      <div className="flex flex-wrap -m-4 text-description text-lg sm:text-[1.25rem] xl:text-[1.55rem] tracking-tight 2xl:text-[2.1rem] whitespace-nowrap">
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>Audrey Hepburn</li>
            <li>Marlon Brando</li>
            <li>Ingrid Bergman</li>
            <li>John Wayne</li>
            <li>Bruce Lee</li>
            <li>Clark Gable</li>
            <li>Jack Nicholson</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>Al Pacino</li>
            <li>Dustin Hoffman</li>
            <li>Paul Newman</li>
            <li>Robert De Niro</li>
            <li>Sean Connery</li>
            <li>Steve McQueen</li>
            <li>Sidney Poitier</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>Gary Cooper</li>
            <li>Grace Kelly</li>
            <li>Fred Astaire</li>
            <li>Peter Sellers</li>
            <li>Gregory Peck</li>
            <li>Lauren Bacall</li>
            <li>Ava Gardner</li>
          </ul>
        </div>
        <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
          <ul className="list-disc list-inside">
            <li>Charlie Chaplin</li>
            <li>Marilyn Monroe</li>
            <li>Humphrey Bogart</li>
            <li>Sharon Tate</li>
            <li>Cary Grant</li>
            <li>Katharine Hepburn</li>
            <li>Orson Welles</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MovieList;

import AccordionGroup from 'components/accordion-group';
import { FAQ_CONTENTS } from 'content/constants';

function FaqSection() {
  return (
    <>
      <h1 className="text-6xl text-center font-adelia text-[2.5rem] md:text-6xl">
        FAQs
      </h1>
      <div className="mt-16 sm:px-10 lg:px-40">
        <AccordionGroup accordionList={FAQ_CONTENTS} />
      </div>
    </>
  );
}

export default FaqSection;

const heroContent = {
  text: `FOCUS BLOC HAS SUCCESSFULLY LAUNCHED AND SOLD OUT ITS FIRST DROP INCLUDING 120 EXCLUSIVE VINTAGE CELEBRITY PHOTOGRAPHS, TAKEN BY JEAN-CLAUDE DEUTSCH BETWEEN 60’s AND EARLY 90’s`,
};

const photosIntroContent = {
  line1: `Each of the 120 photographs are one-to-ones, exclusive to Focus Bloc, of the 25 curated iconic actors, singers and fashion designers present in our first drop.`,
  line2: `Each of the minted photographs has allowed holders to claim the `,
  line3: `Focus Bloc and Paris Match have collaborated to grant the exclusive NFT rights for each minters / holders (See `,
};

const photographerIntroContent = {
  title: `THE PHOTOGRAPHER : JEAN-CLAUDE DEUTSCH`,
  text: 'Jean-Claude Deutsch worked for 40 years as a photographer at Paris Match. The relationship of trust that Jean-Claude Deutsch established with the celebrities, pushes these characters to show him their most personal sides. From an early age, JeanClaude Deutsch has had only one obsession: to become a top reporter at Paris Match. A few years later, he was then nicknamed "The photographer of the stars, the star of the photographers". If the passion plunged him into the intimacy of the greats of this world, (never a stolen photo), this allowed him to make the most heterogeneous and varied encounters. His many releases from Yves Saint-Laurent, David Bowie, Michael Jackson, or Arnold Schwarzenegger, to name a few, have been published in the most recognized magazines',
};

const mintDateContent = {
  line1: 'Mint date : Thursday January 19',
  line2: ' 2023, 7pm CET',
};

export {
  heroContent,
  photosIntroContent,
  photographerIntroContent,
  mintDateContent,
};

import Button from 'components/Button';

function CardsSection() {
  return (
    <section className="w-full">
      <div className="relative items-center w-full py-8">
        <div className="grid w-full grid-cols-1 gap-16 items-center lg:grid-cols-3">
          <div className=" lg:col-span-1 px-4">
            <p className=" text-heading mb-1 text-[1.8rem] lg:text-[2rem] 2xl:text-[2.25rem]  text-center">
              Discover our vision regarding the NFT photography market and learn
              more about vintage photography
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mirror.xyz/focusbloc.eth"
            >
              <Button
                className="mx-auto mt-8 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl max-sm:w-[260px]"
                label="Our blog"
                isBlack
              />{' '}
            </a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:col-span-2">
            <a
              href="https://mirror.xyz/focusbloc.eth/BNt9Sym7HzFo8xzJkOW_dNHo3e0D6Kf8xRnWFRdzA5k"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-full md:h-[320px] object-cover"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-1.jpg"
                alt="dp"
              />
              <div className="bg-brand-black pt-8 pb-6 px-8 text-center w-full text-description font-semibold text-brand-white text-[1.9rem] tracking-wide 2xl:text-[2.25rem]">
                Vintage photography : history and opportunities
              </div>
            </a>
            <a
              href="https://mirror.xyz/focusbloc.eth/QO3Fx6hoRBE4TGea1nCvv0CDWUYGI6WMmymFrl6l9-U"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-full md:h-[320px] object-cover"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-0.png"
                alt="dp"
              />
              <div className="bg-brand-black pt-8 pb-6 px-8 text-center w-full font-semibold text-brand-white text-description text-[1.9rem] tracking-wide 2xl:text-[2.25rem]">
                Photo NFTs the new era of photography?
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardsSection;

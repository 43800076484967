const details = [
  {
    name: 'Jake Fried',
    profession: 'Stop motion animation artist',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(6).png',
    workPlaces: `Christie’s, Tate Modern, Sundance
        film festival, Netflix`,
    aboutPara: `Using ink and white-out to generate hallucinatory vistas, Jake Fried repeatedly modifies and records his images to create mind-bending animations. His films have been auctioned at Christie's, exhibited at the Tate Modern and Sundance Film Festival, and commissioned by Adult Swim, Netflix, and numerous art galleries around the world.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/jakejfried',
  },
  {
    name: 'Michael Sidofsky',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(2).png',
    profession:
      'Professional photographer from Toronto, specializing in elevated travel',
    workPlaces: `SuperRare`,
    aboutPara: `Michael Sidosky is one of the most successful photographer on SuperRare His images aim to portray a unique perspective of places or things that may seem commonplace to the everyday eye, but which trigger some sort of emotional reaction. Whether I'm shooting landscapes, cityscapes, streets or architecture, my goal is to always create a connection between the subject and the viewer.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/mindzeye',
  },
  {
    name: 'Louis Dazy',
    image: './images/louis.png',
    // image:
    //   'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image.png',
    profession: 'Conceptual photographer based in Paris',
    workPlaces: `SuperRare`,
    aboutPara: `His approach combines portraiture, street photography and light work to create highly evocative, cinematic images. Dazy’s work carries us through a heady timeless flow which epitomises the glamour of the 1950s, the sexuality of the 1970s and the high pressure of the 1980s, blending French film styling with illuminated Asian super cities and American diner aesthetics.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/louisdazy',
  },
  {
    name: 'Ian Rogers',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Ian1-3-2.webp',
    profession: 'Chief Experience Officer at Ledger',
    workPlaces: `SuperRare`,
    aboutPara: `Leading at Ledger the consumer-facing business delivering the world’s #1 way to buy and secure cryptocurrency. Prior, Ian was the Chief Digital Officer at LVMH for five transformative years, working with a portfolio of nearly one hundred brands across luxury including Louis Vuitton, Dior, Sephora, and Hennessy.  Ian also sits on the boards of Dr Marten’s and Lyst. Ian spent twenty years bringing digital music to the mainstream, first with Winamp then Yahoo!, Beats, and Apple.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/iancr',
  },
  {
    name: 'Michael Klug',
    // image:
    //   'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(1).png',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image77665.png',
    profession: 'Owner at Whitewall magazine',
    workPlaces: ``,
    aboutPara: `Since March 2006, he is the Founder and Publisher of Whitewall Magazine, Whitewaller and Whitewall.art. Whitewall is the only independent art and luxury lifestyle magazine. Whitewall began to set new standards for high-end, luxury publication. Since then it has gained international acclaim. Published in 12 cities, Whitewaller is a companion for local cultural events. Whitewaller is the ultimate curated guide for major international art and design weeks. Whitewaller covers major contemporary art and design events. Whitewall.art is the digital home of Whitewall and Whitewaller`,
    linkType: 'instagram',
    linkTo: 'https://www.instagram.com/whitewall.art',
  },
  {
    name: 'Jean-Pierre Domingue',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(5).png',
    profession: 'Art photographer based in Paris',
    workPlaces: ``,
    aboutPara: `He began his career in and publicity at the age of 19. He became the assistant of Guy Bourdin, and through this position gained experience. Following this commercial fashion career of 25 years, in 2010 has dedicated his practice to art photography.`,
    linkType: 'instagram',
    linkTo: 'https://www.instagramcom/jeanpierredomingue',
  },
  {
    name: 'Tschuuuly',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/QmcDAsMZ5bgcHJXX6ZfMBY2Z2qPNpH3cxu9ZY1wGJaEdA3.webp',
    profession:
      'Digital artist from Austria, Discord Founder of Alpacadabraz & Toshies moderator of Cryptopunk',
    workPlaces: ``,
    aboutPara: `Studied digital art at university of applied art in Vienna. Pixel art lover, video artists, timebased art, playing with the boarders between analog and digital art. Member of Cryptowiener`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/Tschuuuuly',
  },
  {
    name: 'Mullet',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(4).png',
    profession: 'Web3 entrepreneur and NFT creator and collector',
    workPlaces: ``,
    aboutPara: `Collector of Cryptopunks, XCOPY, Fidenza, Proof Collective, Moonbirds, etc... Member of the Arts DAO Jedi Council. Splitting time between Dubai and Malaga.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/mullet_eth',
  },
  {
    name: 'Brainy',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/MicrosoftTeams-image+(3).png',
    profession: 'Founder of Alpacadabraz & Toshies',
    workPlaces: ``,
    aboutPara: `Punk owner & advisor Twin Flames collector and passionate about vintage photography.`,
    linkType: 'twitter',
    linkTo: 'https://twitter.com/brainy6634',
  },
];

export default details;

import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';

function AccordionGroup({ accordionList }) {
  return (
    <div className="w-full">
      {accordionList.map(({ title, contentText }, index) => (
        <Disclosure key={title}>
          {({ open }) => (
            <Disclosure.Button
              className={classNames(
                'w-full rounded-lg sm:rounded-xl bg-brand-white tracking-wide px-3 pt-4 sm:px-6 lg:px-12 p-3 sm:p-5 sm:pt-8 sm:pb-7 text-left text-lg font-semibold text-brand-black',
                index > 0 && 'mt-4 sm:mt-6',
              )}
            >
              <div className="flex items-center">
                {/* <ChevronUpIcon
                  strokeWidth={0.5}
                  className={classNames(
                    'h-6 sm:h-10 sm:w-10 stroke-0',
                    !open && 'rotate-180 transform',
                  )}
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // fill="currentColor"
                  strokeWidth="0.5"
                  // ariaHidden="true"
                  // className="h-6 sm:h-10 sm:w-10 stroke-0 rotate-180 transform"
                  className={classNames(
                    'h-5 sm:h-10 sm:w-10 fill-brand-black',
                    !open && 'rotate-180 transform',
                  )}
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                    clipRule="evenodd"
                  />
                </svg>

                <h4 className="pl-2 w-full text-center items-center my-auto text-lg md:text-2xl">
                  {title}
                </h4>
              </div>
              <Disclosure.Panel className="mt-12 ml-2 text-xl sm:text-2xl">
                {contentText}
              </Disclosure.Panel>
            </Disclosure.Button>
          )}
        </Disclosure>
      ))}
    </div>
  );
}

AccordionGroup.propTypes = {
  accordionList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      contentText: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AccordionGroup;

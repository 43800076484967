/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { motion, useInView, useMotionValueEvent } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

function ImageCard({ imageSrc, imgRotate, tra }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [positionX, setPositionX] = useState(undefined);

  // useMotionValueEvent(imgRotate, 'change', () => {
  //   setPositionX(ref.current.getBoundingClientRect().x / window.innerWidth);
  // });

  useEffect(() => {
    if (tra > -window.innerWidth / 2) {
      setPositionX(undefined);
    }
    if (isInView && tra < -window.innerWidth / 2) {
      setPositionX(ref.current.getBoundingClientRect().x / window.innerWidth);
    }
  }, [imgRotate, isInView, tra]);

  // const getPositionXRot = () => {
  //   // if (positionX < 0) {
  //   //   return 0;
  //   // }
  //   if (positionX < 0.4 && positionX >= 0) {
  //     return (positionX - 0.4) * -20;
  //   }
  //   return 0;
  // };

  // const getPositionXTransform = () => {
  //   // if (positionX <= 0) {
  //   //   return 0;
  //   // }
  //   if (positionX < 0.4 && positionX >= 0) {
  //     return (positionX - 0.4) * 600;
  //   }
  //   return 0;
  // };

  return (
    <motion.img
      style={{
        width: 'auto',
        rotate: positionX < 0.5 ? (positionX - 0.5) * -30 : 0,
        y: positionX < 0.5 ? (positionX - 0.5) * 600 : 0,
      }}
      ref={ref}
      className=" h-64 sm:h-full w-auto border-[10px] object-contain transition-all duration-300 "
      src={imageSrc}
      alt=""
    />
  );
}

ImageCard.propTypes = {
  imageSrc: PropTypes.bool.isRequired,
  // imgRotate: PropTypes.shape({
  //   canTrackVelocity: PropTypes.bool.isRequired,
  //   current: PropTypes.number.isRequired,
  //   events: PropTypes.shape({
  //     change: PropTypes.shape({
  //       subscriptions: PropTypes.arrayOf(
  //         PropTypes.oneOf([
  //           PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])])
  //             .isRequired,
  //         ]),
  //       ).isRequired,
  //     }).isRequired,
  //   }).isRequired,
  //   hasAnimated: PropTypes.bool.isRequired,
  //   lastUpdated: PropTypes.number.isRequired,
  //   prev: PropTypes.number.isRequired,
  //   timeDelta: PropTypes.number.isRequired,
  //   version: PropTypes.string.isRequired,
  // }).isRequired,
};

export default ImageCard;

import { useState, useEffect } from 'react';

const useMediaQuery = query => {
  const getMatches = q => window.matchMedia(q).matches;
  const [matches, setMatches] = useState(getMatches(query));
  function handleChange() {
    setMatches(getMatches(query));
  }
  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    matchMedia.addEventListener('change', handleChange);
    return () => matchMedia.removeEventListener('change', handleChange);
  }, [query]);
  return matches;
};
export default useMediaQuery;

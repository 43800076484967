import PropTypes from 'prop-types';

function ParagraphCard({ heading, text }) {
  return (
    <div className="w-[350px] sm:w-[500px]">
      {heading && (
        <h2 className=" font-bradlens text-[1.25rem] sm:text-3xl font-semibold mb-4 sm:mb-8 w-[300px] sm:w-[450px]">
          {heading}
        </h2>
      )}
      <div className="text-description w-[300px] sm:w-[450px] text-lg sm:text-2xl lg:text-[1.5rem] 2xl:text-[1.75rem]">
        {text.split('<br/>').map(t => (
          <>
            <p className=" leading-[1.1]">{t}</p>
            <br />
            <div className="mb-2" />
          </>
        ))}
      </div>
    </div>
  );
}

ParagraphCard.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string.isRequired,
};

ParagraphCard.defaultProps = {
  heading: '',
};

export default ParagraphCard;

/* eslint-disable react/no-array-index-key */
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useAccount, useSigner } from 'wagmi';
import config from 'contracts/config';
import { ethers } from 'ethers/lib';
import toast from 'react-hot-toast';
import genesisPassABI from 'contracts/genesisPassABI';
import focusBlocABI from 'contracts/focusBlocABI';
import Button from 'components/Button';
import RainbowKitButton from 'components/RainbowKitButton';

function SelectTokenDropdown() {
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const [selectedValue, setSelectedValue] = useState('');
  const [cost, setCost] = useState('');
  const [tokenIds, setTokenIds] = useState([]);

  const getCost = async () => {
    const contract = new ethers.Contract(
      config.genesis_pass_address,
      genesisPassABI,
      signer,
    );

    const passCost = await contract.getCost(selectedValue);
    setCost(ethers.utils.formatEther(passCost));
    return passCost.toString();
  };

  const getTokenIds = async () => {
    const genesis = new ethers.Contract(
      config.genesis_pass_address,
      genesisPassABI,
      signer,
    );

    const focusBloc = new ethers.Contract(
      config.focus_bloc_address,
      focusBlocABI,
      signer,
    );
    const ids = await focusBloc.tokensOfOwner(address);
    const isClaimedPromises = ids.map(id => genesis.claimed(id));
    const isClaimed = await Promise.all(isClaimedPromises);
    const tokensToReturn = ids.filter((_, index) => !isClaimed[index]);
    if (tokensToReturn.length === 1) setSelectedValue(tokensToReturn[0]);
    setTokenIds(tokensToReturn);

    getCost(selectedValue || 1);
    return tokensToReturn;
  };

  const mint = async () => {
    try {
      const contract = new ethers.Contract(
        config.genesis_pass_address,
        genesisPassABI,
        signer,
      );
      const price = await contract.getCost(selectedValue);
      const tx = await contract.mint(selectedValue, {
        value: price,
      });

      toast.promise(tx.wait(), {
        loading: 'Minting...',
        success: () => {
          getTokenIds();
          return 'Minted!';
        },
        error: 'Error minting',
      });
    } catch (e) {
      const error = e.reason.split(':') || e.message.split(':');
      let message = 'Error minting';
      const [reason, msg] = error;
      if (msg) message = msg;
      else if (reason) message = reason;

      toast.error(message);
    }
  };

  useEffect(() => {
    getTokenIds();
  }, [signer]);

  useEffect(() => {
    if (selectedValue) getCost();
  }, [selectedValue]);

  return (
    <div className="w-full">
      {tokenIds && tokenIds.length > 0 ? (
        <div className="flex flex-col gap-y-4 justify-center w-full">
          {tokenIds.length > 1 ? (
            <Listbox
              value={selectedValue}
              onChange={setSelectedValue}
              className="w-full relative h-24"
            >
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default border-2 border-white pl-3 pr-10 focus:outline-none">
                  <span className="truncate text-center pb-4 pt-6 w-full leading-normal inline-flex items-center justify-center text-4xl font-semibold">
                    {!selectedValue ? 'Select Token' : `Token ${selectedValue}`}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8 sm:pr-12">
                    <svg
                      fill="#FFFFFF"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="800px"
                      height="800px"
                      viewBox="0 0 96.154 96.154"
                      xmlSpace="preserve"
                      className="h-5 w-5"
                    >
                      <g>
                        <path
                          d="M0.561,20.971l45.951,57.605c0.76,0.951,2.367,0.951,3.127,0l45.956-57.609c0.547-0.689,0.709-1.716,0.414-2.61
                                c-0.061-0.187-0.129-0.33-0.186-0.437c-0.351-0.65-1.025-1.056-1.765-1.056H2.093c-0.736,0-1.414,0.405-1.762,1.056
                                c-0.059,0.109-0.127,0.253-0.184,0.426C-0.15,19.251,0.011,20.28,0.561,20.971z"
                        />
                      </g>
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute max-h-72 w-full bg-black overflow-auto border-2 border-white z-10">
                    {tokenIds.map((token, index) => {
                      return (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `text-3xl relative cursor-default select-none  pl-10 pr-4 h-16  flex items-center ${
                              active ? 'bg-white text-black' : 'text-white'
                            }`
                          }
                          value={token.toString()}
                        >
                          {({ selected }) => (
                            <span
                              className={`block pt-2 ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              Token ID {token.toString()}
                            </span>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          ) : null}
          {signer && (
            <div className="w-full flex justify-center">
              <div className="text-brand-white border-r border-brand-greyLight bg-brand-grey text-3xl sm:text-4xl font-semibold w-auto h-24 md:h-[6.75rem] font-founders text-center py-6 pt-8  md:py-4 md:pt-8 px-2 sm:px-4 md:px-8 flex flex-col justify-center items-center">
                1 <br />{' '}
                <span className="text-lg leading-none tracking-normal sm:tracking-wide sm:leading-normal sm:text-[1.35rem]">
                  per FCIMP
                </span>
              </div>
              <div className="text-brand-white bg-brand-grey text-3xl md:text-4xl font-semibold w-auto h-24 md:h-[6.75rem] font-founders text-center py-4 pt-6 md:pt-8 px-4 md:px-16 flex justify-center items-center">
                {cost} <span className="text-lg sm:text-xl">&nbsp;eth</span>
              </div>
              <Button
                label="Mint"
                className="md:text-4xl px-1 md:pt-8 sm:px-6 font-semibold pt-4 max-md:max-w-[8rem] md:w-64 h-24 md:h-[6.75rem]"
                onClick={mint}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex justify-center font-adelia flex-col text-center">
          {isConnected ? (
            <h4 className="font-semibold text-xl md:text-3xl text-white">
              Pass already claimed or FCIMP not owned.
            </h4>
          ) : (
            <div className="w-full flex justify-center">
              <RainbowKitButton />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectTokenDropdown;

import { Disclosure, Transition } from '@headlessui/react';
import Header from 'components/layout/Header';
import guideContent from 'content/guide';
import { useRef, useState } from 'react';

function GuidePage() {
  const { usefulInfo, metaMaskInfo, interestInfo, stepsToAddEthereum, mints } =
    guideContent;

  const buttonRefs = useRef([]);
  const openedRef = useRef(null);

  const clickRecent = index => {
    const clickedButton = buttonRefs.current[index];
    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      return;
    }
    if (openedRef.current?.getAttribute('data-value')) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  return (
    <div className="bg-[#F2F2F2] transform-all duration-200">
      <Header />
      <div className=" h-auto pt-36 lg:pt-44 px-8 sm:px-12 xl:px-20">
        <h1 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[2.5rem] 2xl:text-[2rem] my-10">
          How to buy your first NFT photo with Focus Bloc ?
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 xl:gap-16  justify-items-center font-thin text-lg leading-tight tracking-normal">
          <div className=" col-span-1 md:col-span-5 xl:col-span-4 xl:w-full">
            <div className="bg-white rounded-lg w-full h-min px-4 lg:px-8 py-6 sticky top-24 lg:top-32">
              <InfoCard />
            </div>
          </div>
          <div className="col-span-1 md:col-span-7 xl:col-span-8 w-full text-left py-4">
            <div id="usefulInfo" className=" scroll-mt-36">
              <h2 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.7rem] 2xl:text-[1.75rem]">
                Useful information
              </h2>
              <div className="py-4 grid justify-items-start gap-3">
                {usefulInfo.map(({ title, content }, idx) => (
                  <Disclosure>
                    {({ open }) => (
                      /* Use the `open` state to conditionally change the direction of an icon. */
                      <>
                        <Disclosure.Button>
                          <button
                            type="button"
                            data-value={open}
                            ref={ref => {
                              buttonRefs.current[idx] = ref;
                            }}
                            onClick={() => clickRecent(idx)}
                            className="flex gap-4 items-center"
                          >
                            <p
                              className="font-bold self-start"
                              style={{ fontSize: '20px' }}
                            >
                              {open ? '-' : '+'}
                            </p>
                            <p
                              className={
                                open
                                  ? 'underline font-bold transition-all duration-200 text-left'
                                  : ' text-left'
                              }
                            >
                              {title}
                            </p>
                          </button>
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          className="overflow-hidden"
                          enter="transition transition-[max-height] duration-200 ease-in"
                          enterFrom="transform max-h-0"
                          enterTo="transform max-h-screen"
                          leave="transition transition-[max-height] duration-400 ease-out"
                          leaveFrom="transform max-h-screen"
                          leaveTo="transform max-h-0"
                        >
                          <Disclosure.Panel>
                            {' '}
                            <p className="pb-2">
                              {content.map(c => (
                                <span>
                                  {c}
                                  <br />
                                </span>
                              ))}
                            </p>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
            <div id="metamask" className=" mt-6 scroll-mt-36">
              <div className="flex gap-4 items-center my-2">
                {/* <p
                  className="font-bold font-md pr-1"
                  style={{ fontSize: '32px' }}
                >
                  -
                </p> */}
                <h2 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.7rem] 2xl:text-[1.75rem]">
                  Create a wallet on metamask
                </h2>
              </div>

              <div className="pb-4 space-y-[0.05rem]">
                <div className="flex gap-4 items-center ">
                  <p className="font-3xl min-w-[16px] self-start">1.</p>
                  <p className="self-start">
                    Go to the Metamask website (
                    <span>
                      <a
                        href="https://metamask.io"
                        className=" text-blue-700 underline"
                      >
                        https://metamask.io
                      </a>
                      )
                    </span>{' '}
                    and click on the &quot;Get started&quot; button.
                  </p>
                </div>
                {metaMaskInfo.map((text, idx) => {
                  return (
                    <div className="flex gap-4 items-center">
                      <p className="font-3xl min-w-[16px] self-start">
                        {idx + 2}.
                      </p>
                      <p className=" self-start">{text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div id="interest" className=" mt-6 scroll-mt-36">
              <div className="flex gap-4 items-center my-2">
                {/* <p
                  className="font-bold font-md pr-1"
                  style={{ fontSize: '32px' }}
                >
                  -
                </p> */}
                <h2 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.7rem] 2xl:text-[1.75rem]">
                  Register your interest on our website
                </h2>
              </div>

              <div className="py-4">
                {interestInfo.map((text, idx) => {
                  return (
                    <div className="flex gap-4 items-center">
                      <p className="font-3xl min-w-[16px] self-start">
                        {idx + 1}.
                      </p>
                      <p>{text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div id="wallet" className=" mt-6 scroll-mt-36">
              <div className="flex gap-4 items-center my-2">
                {/* <p
                  className="font-bold font-md or-1"
                  style={{ fontSize: '32px' }}
                >
                  -
                </p> */}
                <h2 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.7rem] 2xl:text-[1.75rem]">
                  Add Ethereum to your metamask wallet
                </h2>
              </div>

              <div className="py-4">
                {stepsToAddEthereum.map((text, idx) => {
                  return (
                    <div className="flex gap-4 items-center">
                      <p className="font-3xl min-w-[16px] self-start">
                        {idx + 1}.
                      </p>
                      <p className="self-start">{text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div id="mint" className=" mt-6 scroll-mt-36">
              <div className="flex gap-4 items-center my-2">
                {/* <p
                  className="font-bold font-md pr-1"
                  style={{ fontSize: '32px' }}
                >
                  -
                </p> */}
                <h2 className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.7rem] 2xl:text-[1.75rem]">
                  Mint on our website
                </h2>
              </div>

              <div className="pb-4">
                {mints.map((text, idx) => {
                  return (
                    <div className="flex gap-4 items-center">
                      <p className="font-3xl min-w-[16px] self-start">
                        {idx + 1}.
                      </p>
                      <p>{text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InfoCard() {
  const { disclosure } = guideContent;
  const [currentDiv, setCurrentDiv] = useState(0);
  // const buttonRefs = useRef([]);
  // const openedRef = useRef(null);

  // const clickRecent = index => {
  //   const clickedButton = buttonRefs.current[index];
  //   if (clickedButton === openedRef.current) {
  //     openedRef.current = null;
  //     return;
  //   }
  //   if (openedRef.current?.getAttribute('data-value')) {
  //     openedRef.current?.click();
  //   }
  //   openedRef.current = clickedButton;
  // };
  return (
    <>
      <div className="space-y-3 grid justify-items-start">
        {disclosure.map(({ title, content, sectionId }, idx) => (
          // <Disclosure key={title}>
          //   {({ open }) => (
          //     /* Use the `open` state to conditionally change the direction of an icon. */
          //     <>
          //       <Disclosure.Button>
          //         <a href={`#${sectionId}`}>
          //           <button
          //             type="button"
          //             data-value={open}
          //             ref={ref => {
          //               buttonRefs.current[idx] = ref;
          //             }}
          //             onClick={() => clickRecent(idx)}
          //             className="font-bold flex"
          //           >
          //             <span
          //               className={
          //                 open
          //                   ? 'min-w-[36px] text-left inline-block transition-all duration-200 self-start'
          //                   : 'min-w-[28px] text-left inline-block font-bold opacity-50 transition-all duration-200 self-start'
          //               }
          //             >
          //               {idx + 1}.
          //             </span>

          //             <span
          //               className={
          //                 open
          //                   ? 'underline text-left'
          //                   : ' font-bold opacity-50 text-left'
          //               }
          //             >
          //               {title}
          //             </span>
          //           </button>
          //         </a>{' '}
          //       </Disclosure.Button>
          //       <Transition
          //         show={open}
          //         className="overflow-hidden"
          //         enter="transition transition-[max-height] duration-200 ease-in"
          //         enterFrom="transform max-h-0"
          //         enterTo="transform max-h-screen"
          //         leave="transition transition-[max-height] duration-400 ease-out"
          //         leaveFrom="transform max-h-screen"
          //         leaveTo="transform max-h-0"
          //       >
          //         <Disclosure.Panel>
          //           {' '}
          //           <p className="pb-4">{content}</p>
          //         </Disclosure.Panel>
          //       </Transition>
          //     </>
          //   )}
          // </Disclosure>
          <>
            <a href={`#${sectionId}`}>
              <button
                type="button"
                // onClick={() => clickRecent(idx)}
                onClick={() => {
                  setCurrentDiv(idx);
                }}
                className="font-bold flex"
              >
                <span
                  className={
                    idx === currentDiv
                      ? 'min-w-[36px] text-left inline-block transition-all duration-200 self-start'
                      : 'min-w-[28px] text-left inline-block font-bold opacity-50 transition-all duration-200 self-start'
                  }
                >
                  {idx + 1}.
                </span>

                <span
                  className={
                    idx === currentDiv
                      ? 'underline text-left'
                      : ' font-bold opacity-50 text-left'
                  }
                >
                  {title}
                </span>
              </button>
            </a>
            {idx === 0 ? <p className="pb-4">{content}</p> : null}
          </>
        ))}
      </div>
      <div className="mt-8">
        <p className="font-bold mb-4 ">
          For any additional information, contact us :
        </p>
        <div className="flex gap-4 items-center ">
          <img
            className="h-6 w-6"
            src="/images/icons/envelop.png"
            alt="mail-icon"
          />
          <a
            className="break-words block overflow-hidden"
            href="mailto:contact@focusbloc.io"
          >
            contact@focusbloc.io
          </a>
        </div>
        <div className="flex gap-4 items-center mt-6">
          <img className="h-6 w-6" src="/images/icons/tw.png" alt="mail-icon" />
          <a
            className="break-words block overflow-hidden"
            href="https://twitter.com/FocusBloc"
          >
            https://twitter.com/FocusBloc
          </a>
        </div>{' '}
        <div className="flex gap-4 items-center mt-6">
          <img className="h-6 w-6" src="/images/icons/in.png" alt="mail-icon" />
          <a
            className="break-words block overflow-hidden"
            href="https://www.instagram.com/focusbloc/"
          >
            https://www.instagram.com/focusbloc/
          </a>
        </div>
      </div>
    </>
  );
}

export default GuidePage;

import PropTypes from 'prop-types';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';

function Layout({ children }) {
  return (
    <div className="text-brand-black">
      <div className="">
        <Header />
        <div className="">{children}</div>
      </div>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;

// import classNames from 'classnames';
import classNames from 'classnames';
import Button from 'components/Button';
import useInterval from 'hooks/useInterval';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// import useInterval from 'hooks/useInterval';
// import { useState } from 'react';
import CardsSection from './CardsSection';
import Images from './Images';
import ModelSection from './ModelsSection';
import PassBanner from './PassBanner';

function Home() {
  // carousel related hooks
  const imagesArr = [
    {
      title: 'Marilyn Monroe on the set of How to marry a millionaire, 1953',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home1.png',
    },
    {
      title: 'Charlie Chaplin, Modern Times, 1936',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home2.png',
    },
    {
      title: 'Marlon Brando and Mary Murphy, The Wild One, 1953',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home3.png',
    },
  ];
  const [currentImage, setCurrentImage] = useState(0);

  useInterval(() => {
    setCurrentImage(s => (s < imagesArr.length - 1 ? s + 1 : 0));
  }, 3000);

  const imagesActors = [
    {
      label: 'monroe',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home11.jpg',
    },
    {
      label: 'charlie chaplin',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home12.jpg',
    },
    {
      label: '',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home13.jpg',
    },
    {
      label: 'monroe',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home14.jpg',
    },
    // {
    //   label: 'charlie chaplin',
    //   url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home15.png',
    // },
    {
      label: '',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home16.png',
    },
    {
      label: '',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/home17.png',
    },
  ];

  return (
    <div className="pt-16 overflow-x-hidden">
      <div className="py-10 2xl:py-16 px-4 md:px-8 bg-[#F2F2F2]">
        <div
          className="border-y-[3px] pt-10 pb-6 md:px-20 border-brand-black "
          id="photographer-info"
        >
          <p className="text-heading lg:text-[2.5rem] 2xl:text-[3.15rem] leading-[0.9]">
            Focus Bloc is an NFT vintage photography platform created for fine
            art collectors, popular culture lovers and dreamers of the past. We
            recover and curate iconic time capsules.
          </p>

          <a
            href="https://opensea.io/collection/focus-bloc-hollywood"
            target="blank"
          >
            <Button
              isBlack
              label="Check the new collection now"
              className="w-max px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] max-sm:text-[1.2rem] xl:text-xl bg-transparent hover:bg-brand-black mt-4 font-normal"
            />
          </a>
        </div>
      </div>

      <div className="">
        <div className="px-4 md:px-28">
          <div className="mt-8">
            <p className="img-label">{imagesArr[currentImage].title}</p>
            <img
              src={imagesArr[currentImage].url}
              className={classNames(
                ' h-[90vh] w-full max-md:h-[70vh] object-cover object-top',
                currentImage !== 2
                  ? 'max-md:object-[60%]'
                  : 'max-md:object-[30%]',
              )}
              alt="banner"
            />
            <div className="grid grid-cols-3 gap-4 bottom-2 mt-2 w-full">
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 0 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 1 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 2 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
            </div>
          </div>
          <div className="mt-12">
            <p className=" text-heading mb-1 text-[1.7rem] sm:text-[1.8rem] tracking-wide lg:text-[2rem]">
              Hollywood : from Golden Age to the first Blockbusters
            </p>
            <p className="text-description text-[1.5rem] sm:text-[1.75rem] tracking-tight 2xl:text-[2rem]">
              The collection of 333 photos is exclusively coming from the
              recovered archives of Movie Star News, featuring Hollywood’s most
              prominent movie stills and icons.
            </p>
            <div className="flex md:gap-6 flex-col md:flex-row md:px-0">
              <a
                href="https://opensea.io/collection/focus-bloc-hollywood"
                target="blank"
              >
                <Button
                  isBlack
                  className="w-full mt-4 mb-2 md:w-auto px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] max-sm:text-[1.2rem] xl:text-xl"
                  label="Check the new collection now"
                />
              </a>
              <Link to="/collection">
                <Button
                  className="w-full md:w-auto my-4 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] max-sm:text-[1.2rem] xl:text-xl"
                  label="Collection Details"
                />
              </Link>
            </div>

            <div className="-ml-4 md:-ml-28 my-8 lg:my-28 md:my-16">
              <Images imagesData={imagesActors} />
            </div>
            <div className="">
              <div className="my-2">
                <p className=" text-heading mb-1 text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2rem]">
                  Movie stills coming from :
                </p>
                <p className="text-description text-[1.5rem] sm:text-[1.75rem] tracking-tight 2xl:text-[2.1rem]">
                  Scarface, The Godfather, James Bond, Citizen Kane, Casablanca,
                  Full Metal Jacket, etc.
                </p>
              </div>
              <div className="my-10 sm:my-4">
                <p className=" text-heading mb-1 text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2rem]">
                  Actresses and actors such as :
                </p>
                <p className="text-description text-[1.5rem] sm:text-[1.75rem] tracking-tight 2xl:text-[2.1rem]">
                  Charlie Chaplin, Marilyn Monroe, Paul Newman, Steve McQueen,
                  Audrey Hepburn, Jack Nicholson, etc.
                </p>
              </div>
            </div>

            <div className="w-full flex md:justify-end">
              <Link to="/register">
                <Button
                  className="w-auto my-4 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl"
                  label="Register for the mint"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="px-4 py-4 md:py-8 sm:px-8 bg-[#F2F2F2]">
          <div className="my-12">
            <PassBanner />
          </div>
          <div className="my-20">
            <ModelSection />
          </div>
          <div className="my-12 px-8">
            <CardsSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import { Link } from 'react-router-dom';

function NoPageFound() {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center bg-brand-black">
      <h1 className="text-9xl font-extrabold text-white tracking-widest">
        404
      </h1>
      <div className="bg-brand-white text-brand-black px-2 text-sm rounded rotate-12 absolute">
        Page Not Found
      </div>
      <Link to="/" className="mt-5">
        <div className="relative inline-block text-sm font-medium text-brand-white group focus:outline-none focus:ring">
          <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-brand-white group-hover:translate-y-0 group-hover:translate-x-0" />
          <span className="relative block px-8 py-3 bg-brand-black border border-current">
            Go Home
          </span>
        </div>
      </Link>
    </main>
  );
}

export default NoPageFound;

import Button from 'components/Button';
import { useAccount, useSigner } from 'wagmi';
import config from 'contracts/config';
import focusBlocABI from 'contracts/focusBlocABI';
import { ethers } from 'ethers/lib';
import React from 'react';

function NftInfo() {
  const { isConnected, address } = useAccount();
  const { data: signer } = useSigner();
  const [balance, setBalance] = React.useState(0);

  const getBalance = async () => {
    const contract = new ethers.Contract(
      config.focus_bloc_address,
      focusBlocABI,
      signer,
    );

    const bal = await contract.balanceOf(address);
    setBalance(Number(bal));
  };

  React.useEffect(() => {
    if (isConnected) {
      getBalance();
    }
  }, [isConnected, signer]);

  return (
    <div>
      {/* <h2 className="font-semibold text-xl md:text-5xl text-white w-full justify-center text-center">
        Sold out ! <br />
        <a
          href="https://opensea.io/collection/focus-bloc-iconic-models-of-the-past"
          rel="noreferrer"
          target="_blank"
          className="text-md md:text-3xl"
        >
          Click here to buy one from secondary market.
        </a>
      </h2>
      <br /> */}
      <div className="w-full flex justify-center mt-12">
        <div className="px-4">
          <a
            href="https://opensea.io/collection/focus-bloc-iconic-models-of-the-past"
            rel="noreferrer"
            target="_blank"
          >
            <Button
              label="View the collection on Opensea"
              className=" mx-auto w-auto my-4 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl"
            />
          </a>
        </div>
      </div>
      {balance > 0 && (
        <div className="w-full flex justify-center font-adelia flex-col text-center">
          <h4 className="font-semibold text-xl md:text-3xl text-white">
            Congratulations! You own {Number(balance)} Focus Bloc.
          </h4>
          <h4 className="font-semibold text-xl md:text-3xl text-white">
            <a href="/focus-bloc-pass">
              Click here to claim your Focus Bloc Pass.
            </a>
          </h4>
        </div>
      )}
    </div>
  );
}

export default NftInfo;

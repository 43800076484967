export const navItemsRight = [
  { label: 'Twitter', link: 'https://twitter.com/FocusBloc' },
  {
    label: 'Opensea',
    link: 'https://opensea.io/collection/focus-bloc-iconic-models-of-the-past',
  },
  { label: 'Discord', link: 'https://discord.gg/focusbloc' },
];

export const navItemsLeft = [
  { label: 'Collection', link: '/collection' },
  { label: 'Curation board', link: '/curation-board' },
];

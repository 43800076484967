/* eslint-disable no-unused-vars */
import {
  motion,
  useInView,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import { useRef, useState } from 'react';
import ImageReveal from 'components/animations/ImageReveal';
import HorizontalScroll from 'components/animations/HorizontalScroll';
import GridZoom from 'components/animations/GridZoom';
import FaqSection from 'components/faqs';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Footer from 'components/layout/Footer';
import useMediaQuery from 'hooks/useMediaQuery';
import MovieList from './MovieList';
import ImageList from './ImageList';

function CollectionPage() {
  const imageBannerRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: imageBannerRef });
  const headingTransform = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ['-30%', '25%', '50%'],
  );
  const imgT = useTransform(scrollYProgress, [0, 0.5, 1], ['0%', '0%', '-75%']);

  const textColorTransform = useTransform(
    scrollYProgress,
    [0, 0.75, 0.76],
    ['white', 'white', 'black'],
  );

  const textDescColorTransform = useTransform(
    scrollYProgress,
    [0, 0.72, 0.73],
    ['white', 'white', 'black'],
  );
  const [currentDiv, setCurrentDiv] = useState(1);

  // useInterval(() => {
  //   setCurrentImage(s => (s < imagesArr.length - 1 ? s + 1 : 0));
  // }, 3000);

  const parentRef = useRef(null);

  const introRef = useRef(null);
  const isIntroInView = useInView(introRef);

  const historyRef = useRef(null);
  const isHistoryInView = useInView(historyRef);

  const collectionRef = useRef(null);
  const isCollectionInView = useInView(collectionRef);

  const faqRef = useRef(null);
  const isFaqInView = useInView(faqRef);

  const { scrollYProgress: scrollYProgressPage } = useScroll({
    target: parentRef,
  });
  useMotionValueEvent(scrollYProgressPage, 'change', () => {
    if (isIntroInView) {
      setCurrentDiv(1);
    }
    if (isHistoryInView) {
      setCurrentDiv(2);
    }
    if (isCollectionInView) {
      setCurrentDiv(3);
    }
    if (isFaqInView) {
      setCurrentDiv(4);
    }
  });
  const isBiggerThanSm = useMediaQuery('(min-width: 640px)');
  return (
    <div ref={parentRef} className="relative  ">
      <div className="fixed z-50">
        {/* <div className="w-screen bg-[#F2F2F2] pb-1 pt-3 px-4 max-sm:text-sm border border-b-brand-black border-solid text-center text-black font-bold">
          The mint of our Hollywood collection is on May 2nd, 6.30pm CET
          /12.30pm EST
        </div> */}
        <div className="fixed flex max-sm:flex-wrap top-0 justify-between left-0 w-full pb-2 pt-4 px-4 lg:px-16 z-50 bg-brand-black text-brand-white text-sm sm:text-xl">
          <a href="/">{'<'} Back to homepage</a>
          <a
            className={classNames(
              'hidden md:block',
              currentDiv === 1 && 'underline',
            )}
            href="#introduction"
          >
            1.Introduction
          </a>
          <a
            className={classNames(
              'hidden md:block',
              currentDiv === 2 && 'underline',
            )}
            href="#history"
          >
            2.History
          </a>
          <a
            className={classNames(
              'hidden md:block',
              currentDiv === 3 && 'underline',
            )}
            href="#collection"
          >
            3.Collection Preview
          </a>
          <a
            className={classNames(
              'hidden md:block',
              currentDiv === 4 && 'underline',
            )}
            href="#faq"
          >
            4.FAQs
          </a>
        </div>
      </div>

      {/* <div className="fixed md:hidden left-16 top-4 px-8 py-4 rounded-full z-50 text-brand-white bg-brand-black">
        <a href="/">{'<'} Back to homepage</a>
      </div> */}
      <div className="fixed flex bottom-2 sm:top-12 sm:w-full sm:right-4 w-screen mb-2 mt-2 md:mt-6 px-4 sm:px-4 h-[56px] md:h-[64px] z-50 ">
        {/* <div className=" right-0 min-h-[64px] left-auto max-w-[12rem] sm:max-w-[17rem] w-full h-full  rounded-full bg-[#650301] absolute filter blur-[8px] opacity-90" /> */}
        <a
          href="https://opensea.io/collection/focus-bloc-hollywood"
          target="blank"
          className="mx-auto sm:ml-auto sm:mr-0"
        >
          <Button
            className="w-max sm:ml-auto px-4 sm:px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] text-xl 2xl:py-[1rem] xl:text-xl 2xl:text-3xl"
            label="Check the new collection now"
          />
        </a>
      </div>
      {isBiggerThanSm ? (
        <div ref={imageBannerRef} className="h-[300vh]">
          <div className="h-screen sticky" style={{ top: 0 }}>
            <motion.div
              className="h-screen relative bg-cover bg-center"
              style={{
                // backgroundImage: `url(${imagesArr[currentImage]})`,
                backgroundImage:
                  'url(https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-9.jpg)',
                top: imgT,
              }}
            >
              {/* <div className="grid grid-cols-4 gap-4 bottom-2 px-8 left-0 w-full absolute">
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 0 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 1 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 2 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
              <div
                className={classNames(
                  'h-2 rounded-md',
                  currentImage === 3 ? 'bg-brand-black' : 'bg-brand-white',
                )}
              />
            </div> */}
              {/* <button
              className=" bottom-8 px-8 right-0 w-auto absolute"
              onClick={() =>
                setCurrentImage(s => (s < imagesArr.length - 1 ? s + 1 : 0))
              }
              type="button"
            >
              <div className="flex justify-around items-center leading-none pt-4 pb-2 gap-4 text-[1.35rem] hover:cursor-pointer 2xl:text-2xl h-[3.5rem] w-36 rounded-full border border-black backdrop-blur-md mr-8">
                <p>
                  {currentImage + 1}/{imagesArr.length}
                </p>
                <p>{'>'}</p>
              </div>
            </button> */}
            </motion.div>
            <motion.div
              style={{ top: headingTransform }}
              className="absolute top-0 max-sm:-mt-16 z-5 text-center w-full"
            >
              <motion.h1
                style={{ color: textColorTransform }}
                className="font-bradlens text-[10vw] leading-none"
              >
                hollywood
              </motion.h1>

              <motion.h1
                style={{ color: textDescColorTransform }}
                className="font-bradlens text-[4vw] 2xl:text-[5vw] leading-none"
              >
                from Golden Age to the first Blockbusters
              </motion.h1>
            </motion.div>
          </div>
        </div>
      ) : (
        <div
          className="h-screen mb-96 relative bg-cover bg-center"
          style={{
            // backgroundImage: `url(${imagesArr[currentImage]})`,
            backgroundImage:
              'url(https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-mobile.png)',
          }}
        >
          <div className="h-screen w-full flex items-center backdrop-brightness-50">
            <div className="text-center w-full max-sm:text-brand-white">
              <p className="font-bradlens text-6xl sm:text-[10vw] leading-none">
                hollywood
              </p>
              <p className="font-bradlens tracking-tight text-[5.5vw] 2xl:text-[5vw] leading-none">
                from Golden Age to the first Blockbusters
              </p>{' '}
            </div>
          </div>
        </div>
      )}
      <div className=" -mt-[80vh] sm:-mt-36 2xl:-mt-48" />
      <div ref={introRef} id="introduction">
        <ImageReveal />
      </div>
      <div ref={historyRef} id="history">
        <HorizontalScroll />
      </div>
      <div ref={collectionRef} id="collection">
        <GridZoom />
        <div className="px-12 md:px-24 xl:px-32 sm:mt-16">
          <MovieList />
          <ImageList />
        </div>
      </div>
      <div ref={faqRef} id="faq" className="py-16 px-12 sm:px-32">
        <FaqSection />
      </div>
      <Footer />
    </div>
  );
}

export default CollectionPage;

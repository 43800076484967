import PropTypes from 'prop-types';
import './slider.css';

function Images({ imagesData, isModels }) {
  return (
    <div className="slider">
      <div className={isModels ? 'slide-track-2' : 'slide-track'}>
        {imagesData.map(image => (
          <div className="slide" key={image.url}>
            <img
              className="h-full w-full object-contain"
              src={image.url}
              alt={image.url}
            />
          </div>
        ))}
        {imagesData.map(image => (
          <div className="slide" key={image.url}>
            <img
              className="h-full w-full object-contain"
              src={image.url}
              alt={image.url}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

Images.propTypes = {
  imagesData: PropTypes.string.isRequired,
  isModels: PropTypes.bool,
};

Images.defaultProps = {
  isModels: false,
};

export default Images;

import Button from 'components/Button';
import { Link } from 'react-router-dom';
import Pass from './Pass';

function PassBanner() {
  return (
    <div className="md:px-3 lg:px-6 relative bg-brand-black items-center w-full py-6 2xl:py-12">
      <div className="max-lg:items-center grid w-full grid-cols-1 pt-12 pb-6 gap-8 xl:gap-16 lg:grid-cols-12">
        <div className=" hidden lg:block lg:col-span-1" />
        <div className=" px-[10%] md:px-[20%] xl:ml-6 lg:px-4 lg:col-span-4">
          <Pass />
        </div>
        <div className=" lg:col-span-7 px-8 2xl:px-20 grid place-content-center">
          <p className="text-heading mb-1 text-[1.8rem] lg:text-[2rem] 2xl:text-[2.25rem] text-brand-white leading-[1.15] text-center md:text-left">
            The Focus Bloc Pass gives many advantages to its holders including
            guaranteed whitelists and 25% discount on our next collections.{' '}
          </p>
          <Link to="/focus-bloc-pass">
            <Button
              className="mt-4 w-fit max-lg:mx-auto my-4 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl"
              label="Learn more"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PassBanner;

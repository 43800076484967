import { FOCUS_BLOC_PASS_ADVANTAGES_HEADER } from 'content/constants';

function AdvantagesHeader() {
  return (
    <>
      <h1 className="font-adelia text-4xl sm:text-6xl text-center text-brand-white">
        {FOCUS_BLOC_PASS_ADVANTAGES_HEADER.title}
      </h1>
      <h6 className="text-3xl text-center mt-6 w-full font-bold text-brand-white">
        {FOCUS_BLOC_PASS_ADVANTAGES_HEADER.subTitle}
      </h6>
    </>
  );
}

export default AdvantagesHeader;

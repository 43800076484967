const guideContent = {
  usefulInfo: [
    {
      title: 'What is a NFT photo ?',
      content: [
        'An NFT photo is a unique digital asset that is stored on a blockchain. Each NFT photo has a unique digital signature that makes it one-of-a-kind and distinguishes it from other similar assets. Because of their uniqueness, NFTs photo can be bought, sold, and traded like a piece of fine art and their value is determined by supply and demand.',
      ],
    },
    {
      title: 'What is a mint ?',
      content: [
        'In the context of NFTs, "mint" refers to the process of creating a new NFT on a blockchain. When someone mints an NFT, they are essentially creating a unique digital asset and recording it on the blockchain. Regarding Focus Bloc, this process involves using  an existing exclusive photo and then a specialized software to create a digital certificate of ownership, which is stored on the blockchain. The act of minting an NFT also involves setting various parameters, such as the name and description of the NFT and the price at which it will be sold. When users mint a photo from Focus Bloc, they’re basically purchasing the ownership of the photo, stored on a blockchain, Once the NFT is minted, it can be bought, sold, and traded like any other asset.',
      ],
    },
    {
      title: 'What is a blind mint ?',
      content: [
        'A blind mint is a type of NFT sale where the buyer does not know what they are purchasing before the transaction is completed. In our blind mint sale, we offer a limited number of NFTs for sale at a fixed price, show a preview of the collection but we do not reveal the NFT photos until after they have been all purchased.',
        'Blind mint sales have become increasingly popular in the NFT market as a way to generate interest and excitement around new releases. ​',
      ],
    },
    {
      title: 'What’s an allowlist?',
      content: [
        'In the context of Focus Bloc, we use the allowlist system to allow verified buyers to access our mint. Potential buyers can request to be on the allowlist and we randomly select a group of verified buyers to access the mint.',
        'For our mint, we require users to have an Ethereum address and a verified email address.',
      ],
    },
    {
      title: 'What’s the advantages of buying a NFT photo vs a photo print?',
      content: [
        'One of the advantages of buying an NFT photo (as opposed to a photo print) is that it is a unique and verifiable digital asset. Unlike a photo print, which can be reproduced and replicated, an NFT photo is stored on a blockchain and has a unique digital signature that verifies its authenticity and ownership.',
        'Another advantage of buying an NFT photo is that it can be easily traded and sold on NFT marketplaces, potentially increasing in value over time based on supply and demand. NFT photos can also offer additional features and benefits that are not available with physical photo prints, such as access to exclusive events or merchandise.',
      ],
    },
    // {
    //   title: 'Where is my NFT stored after purchase ?',
    //   content:
    //     'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
    // },
  ],
  metaMaskInfo: [
    // `Go to the Metamask website <b>(https://metamask.io)</b> and click on the "Get started" button.`,
    'Install the Metamask browser extension for your preferred browser (Chrome, Firefox, Edge, or Brave).',
    'Once the extension is installed, click on the Metamask icon in your browser toolbar to launch it.',
    `Click on the "Create a Wallet" button.`,
    'Follow the prompts to create a password. Make sure to use a strong, unique password and to store it somewhere safe.',
    'After creating your password, Metamask will give you a 12-word seed phrase. Write down these words and keep them in a safe place. They are important because they can be used to restore your wallet if you ever lose access to it.',
    `Once you have written down your seed phrase, click on "Next" to confirm that you have saved your seed phrase.`,
    'You have now created a Metamask wallet. You can now use it to store, send, and receive cryptocurrencies.',
    'Remember to never share your seed phrase with anyone and to keep it in a safe place. With Metamask, you are the only one who has access to your funds.',
  ],
  interestInfo: [
    'Open your Metamask wallet by clicking on the extension icon in your browser',
    'Copy your ethereum address',
    'Paste it on our register page : focusbloc.io/register',
  ],
  stepsToAddEthereum: [
    'Open your Metamask wallet by clicking on the extension icon in your browser',
    `Click on the "Receive" button to get your Ethereum wallet address. You can either copy the address or use the QR code to receive Ethereum from another wallet.`,
    'Go to the wallet or exchange from which you want to send Ethereum and initiate a transfer.',
    'Enter the Ethereum address of your Metamask wallet as the recipient address.',
    'Enter the amount of Ethereum you want to send and confirm the transaction.',
    'Wait for the transaction to be confirmed on the Etherscan.io.',
    'Once the transaction is confirmed, you should see the Ethereum balance in your Metamask wallet.',
  ],
  mints: [
    'Go to our mint page on the date and time of the mint',
    'Follow the instructions on the mint page until you have minted your NFT',
    'As it’s a blind mint, Your NFT will be revealed 24h after your purchase on Opensea.io',
    'You will then be able to view your NFT by connecting your Metamask on Opensea.io',
  ],
  disclosure: [
    {
      title: 'Useful information',
      content:
        'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
      sectionId: 'usefulInfo',
    },
    {
      title: 'Create a wallet on Metamask',
      content:
        'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
      sectionId: 'metamask',
    },
    {
      title: 'Register your interest on our website',
      content:
        'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
      sectionId: 'interest',
    },
    {
      title: 'Add Ethereum to your wallet',
      content:
        'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
      sectionId: 'wallet',
    },
    {
      title: 'Mint',
      content:
        'In order to be able to buy an NFT photo through our website, you will have to follow the following steps',
      sectionId: 'mint',
    },
  ],
};

export default guideContent;

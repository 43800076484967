import { useEffect } from 'react';
import SplitType from 'split-type';
import debounce from '../utils/debounce';

const toNearest = value => {
  // value is a percentage of the screen height which is covered by the div.
  if (value <= 60 && value > 0) return 1;
  //   || (value > 40 && value < 50)
  if (value < 90 && value > 60) return 0.4;
  return 0.1;
};

function useTextAnimation(targetId) {
  const handleAnimation = () => {
    const parentWrapperOfParagraph = document.getElementById(targetId);
    if (!parentWrapperOfParagraph) return;
    const { y } = parentWrapperOfParagraph.getBoundingClientRect();
    const { innerHeight } = window;

    const splittedLines = document.querySelectorAll(`#${targetId} .line`);

    // should be between 30 and 90
    const divideFactor = 80 / 100;

    if (y < innerHeight * divideFactor) {
      splittedLines.forEach(line => {
        const lineCopy = line;
        const { top } = line.getBoundingClientRect();

        lineCopy.style.opacity = toNearest(
          (innerHeight / (innerHeight - top)) * 20,
        );
      });
    }
  };

  useEffect(() => {
    const parentWrapperOfParagraph = document.getElementById(targetId);

    const text = new SplitType(parentWrapperOfParagraph, { types: 'lines' });

    // const splittedLines = document.querySelectorAll('.line');
    const splittedLines = text.lines;

    splittedLines.forEach(line => {
      const lineCopy = line;
      lineCopy.style.opacity = 0.1;

      // adding transition when the opacity changes.
      line.classList.add('transition-opacity', 'duration-150', 'ease-linear');
    });

    window.addEventListener('scroll', debounce(handleAnimation, 4));

    return () =>
      window.removeEventListener('scroll', debounce(handleAnimation, 4));
  }, []);
}

export default useTextAnimation;

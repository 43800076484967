// import Button from 'components/Button';
import NavItemsExternal from 'components/layout/NavItemsExternal';
import MailChimpForm from 'components/layout/Footer/MailChimpForm';

function FooterButtonGroup() {
  return (
    <div className="  pt-6 md:px-0 w-full flex flex-col gap-10 md:gap-8 items-center justify-center">
      <div className="flex justify-center md:gap-[0.1rem] w-full text-2xl md:text-3xl px-0 md:px-0 font-semibold tracking-wider md:-mt-6">
        {/* <input
          type="text"
          id="email"
          className="text-brand-greyLight text-[1.35rem] sm:text-3xl md:text-[2rem] bg-brand-grey tracking-wider block flex-grow-0 w-52 sm:w-80 md:w-[22rem] lg:w-auto h-20 md:h-[6.5rem] pb-3 pl-2 md:pl-8 pt-6  xl:ml-20 font-founders"
          placeholder="Enter email address"
        />
        <Button
          className="text-[1.35rem] leading-none sm:leading-normal sm:text-3xl md:text-[2rem] lg:text-4xl pt-3 sm:pt-0 px-2 sm:px-4 flex-grow xl:w-[16rem] md:flex-grow-0 font-semibold h-20 md:h-[6.5rem] "
          label="Stay in the loop"
        /> */}
        <MailChimpForm />
      </div>
      <div className="text-[1.25rem] lg:text-[1.5rem] 2xl:text-[2rem] text-center font-semibold tracking-wider">
        <NavItemsExternal direction="reverse" />
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer className="bg-brand-black pt-12 2xl:pt-20 w-full px-4 md:px-16 lg:px-32 z-10 text-3xl font-founders font-semibold text-brand-white">
      <div className="w-full flex justify-center">
        <a href="./" className="max-sm:-mb-8">
          <img
            src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Focus+Bloc+logo.png"
            className="w-[12rem] md:w-[16rem] 2xl:w-[24rem]"
            alt="focus bloc logo"
          />
        </a>
      </div>
      <div className="flex items-center lg:flex-row mt-1 sm:mt-4 flex-col justify-between">
        <FooterButtonGroup />
      </div>
      <div className="w-full text-center mt-8 sm:mt-16 pb-8 sm:pb-12 md:mt-16 2xl:mt-32 text-description font-medium text-2xl md:text-[1.65rem] 2xl:text-[1.8rem]">
        Copyright Focus Bloc 2023
      </div>
    </footer>
  );
}

export default Footer;

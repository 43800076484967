import Profile from 'pages/board/Panel/Profile';
import panelDetails from 'content/panelDetails';

export default function Panel() {
  //   const arr = new Array(10);
  return (
    <section className="w-full px-4 md:px-8 xl:px-36 mx-auto text-brand-white">
      <h2 className="text-[4rem] tracking-normal leading-snug md:tracking-wide font-semibold sm:text-[5rem] sm:max-w-5xl text-center m-auto mb-24">
        Our panel of curation
      </h2>
      {/* <p className="mb-16 text-lg text-gray-500">
        Get insights to dig down into what&apos;s powering your growth the most.
      </p> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-16 lg:gap-x-24 xl:gap-x-36 gap-y-28 lg:gap-y-40">
        {panelDetails.map(profile => (
          <Profile
            name={profile.name}
            profession={profile.profession}
            workplaces={profile.workPlaces}
            linkTo={profile.linkTo}
            linkType={profile.linkType}
            aboutPara={profile.aboutPara}
            imageLink={profile.image}
          />
        ))}
      </div>
    </section>
  );
}

import Slider from 'infinite-react-carousel';
import { MODELS_COLLECTION } from 'content/constants';
import { useState, useRef } from 'react';
import classNames from 'classnames';
import useMediaQuery from 'hooks/useMediaQuery';

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 1281px)');
  const isMd = useMediaQuery('(max-width: 1280px)');
  const isLg = useMediaQuery('(max-width: 1024px)');
  const isSm = useMediaQuery('(max-width: 768px)');
  function getResponsiveSlides() {
    if (isSm) return 2;
    if (isLg) return 3;
    if (isMd) return 4;
    if (isDesktop) return 5;
    return 2;
  }
  const settings = {
    autoplay: false,
    centerMode: true,
    slidesToShow: getResponsiveSlides(),
    // slidesToShow: maxWSm ? (maxWxs ? 2 : 3) : 5,
    arrows: false,
    afterChange: () => {
      setActiveIndex(carouselRef?.current?.innerSlider?.state.activeIndex);
    },
  };
  return (
    <div>
      <div className="text-center mb-16 max-md:mt-20 max-sm:px-4">
        <h1 className="font-adelia text-5xl tracking-normal leading-snug md:tracking-wide sm:text-6xl sm:max-w-5xl text-center m-auto mb-8">
          ICONIC MODELS OF THE PAST
        </h1>
        <h4 className="font-semibold text-xl md:text-3xl text-white">
          Preview of the collection (25/120)
        </h4>
        <h4
          className={classNames('font-semibold text-xl md:text-3xl text-white')}
        >
          Each minted NFT will feature one of the listed celebrities below
        </h4>
      </div>
      <div>
        <Slider
          autoplay={settings.autoplay}
          centerMode={settings.centerMode}
          slidesToShow={settings.slidesToShow}
          arrows={settings.arrows}
          afterChange={settings.afterChange}
          ref={carouselRef}
        >
          {MODELS_COLLECTION.map((item, i) => (
            <div
              tabIndex={0}
              role="button"
              id={item.id}
              key={item.id}
              onClick={() => {
                setActiveIndex(i);
                carouselRef.current.slickGoTo(i);
              }}
              onKeyDown={() => {}}
              className="text-center px-[10%] pb-[8%] md:pb-[10%] break-words sm:break-normal"
            >
              <h4
                className={classNames(
                  'font-semibold text-lg md:leading-normal md:text-2xl hover:cursor-pointer h-24 leading-[1.2]',
                  i === activeIndex ? 'text-white' : 'text-[#797979]',
                )}
              >
                {item.name}
              </h4>
            </div>
          ))}
        </Slider>
        <div className="mx-auto px-8 lg:px-16 h-[60vh] md:h-[80vh] lg:h-[90vh] md:w-max max-w-[100vw]">
          <img
            src={MODELS_COLLECTION?.[activeIndex]?.image}
            alt={MODELS_COLLECTION?.[activeIndex]?.name}
            className="mx-auto w-auto max-h-auto md:max-h-[70vh] lg:max-h-[80vh] object-contain object-top"
            loading="lazy"
          />
          <span className="absolute text-white mt-5 text-left text-lg md:text-2xl">
            Jean-Claude Deutsch / Paris Match
          </span>
        </div>
      </div>
    </div>
  );
}
export default Carousel;

import { photographerIntroContent } from 'content/home';
import useMediaQuery from 'hooks/useMediaQuery';
import useTextAnimation from 'hooks/useTextAnimation';
import { useEffect, useState } from 'react';

const images = [
  'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/closup.png',
  'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/photographer.png',
  'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/retro.png',
];

function PhotographerInfo() {
  useTextAnimation('photographer-info');
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const slider = setInterval(() => {
      setCurrentImage(state => (state > images.length - 2 ? 0 : state + 1));
    }, 3000);
    return () => clearInterval(slider);
  }, [currentImage]);

  return (
    <div className="w-full px-4 md:px-8 lg:px-16">
      <h1 className="font-adelia text-[2.05rem] tracking-normal leading-snug md:tracking-wide sm:text-6xl sm:max-w-2xl text-left md:text-center m-auto mb-24">
        {photographerIntroContent?.title}
      </h1>
      <div className="h-auto flex md:justify-end gap-4 sm:gap-8 mt-[5%]">
        {!useMediaQuery('(max-width: 768px)') && (
          <div className="hidden md:block w-1/2 h-[650px] lg:h-[100vh] sticky translate-y-[128px] top-0">
            <div className="hidden md:flex justify-center">
              <img
                className="hidden  md:block max-h-[600px] lg:max-h-[650px] object-contain"
                alt=""
                src={images[currentImage]}
              />
            </div>
          </div>
        )}

        <div className="flex-1 flex w-auto">
          <div
            className=" mb-1 w-full text-[2.1rem] sm:text-[2.5rem] lg:text-[3rem] xl:text-[3.4rem] font-semibold leading-[0.9] pl-4 lg:px-8 font-founders md:text-center break-words"
            id="photographer-info"
          >
            {photographerIntroContent?.text}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotographerInfo;

import { BLOC_PASS_ADVANTAGES_ITEMS } from 'content/constants';
import AdvantagesGridItemView from './AdvantagesGridItemView';
import AdvantagesHeader from './AdvantagesHeader';

function AdvantagesSection() {
  return (
    <div className="mt-44 text-brand-white">
      <AdvantagesHeader />
      <div className="mt-28 sm:w-1/2 sm:ml-[10%] ">
        <AdvantagesGridItemView
          title={BLOC_PASS_ADVANTAGES_ITEMS[0].title}
          content={BLOC_PASS_ADVANTAGES_ITEMS[0].content}
          titleId="adv-title-1"
          paragraphId="adv-para-1"
        />
      </div>
      <div className="mt-32 md:mt-40 sm:w-[60%] sm:ml-auto sm:pr-20 lg:pr-40">
        <AdvantagesGridItemView
          title={BLOC_PASS_ADVANTAGES_ITEMS[1].title}
          content={BLOC_PASS_ADVANTAGES_ITEMS[1].content}
          titleId="adv-title-2"
          paragraphId="adv-para-2"
        />
      </div>
      <div className="mt-32 md:mt-40 sm:w-1/2 2xl:w-1/3 sm:ml-[10%]">
        <AdvantagesGridItemView
          title={BLOC_PASS_ADVANTAGES_ITEMS[2].title}
          hasListContent
          listContents={BLOC_PASS_ADVANTAGES_ITEMS[2].listContents}
          titleId="adv-title-3"
        />
      </div>
    </div>
  );
}

export default AdvantagesSection;

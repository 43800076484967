import PropTypes from 'prop-types';
import NavItem from 'components/layout/NavItem';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

export default function NavItemsExternal({ direction, open, setOpen }) {
  const rightItems = [
    { label: 'Mirror', link: 'https://mirror.xyz/focusbloc.eth', id: '4' },
    { label: 'Twitter', link: 'https://twitter.com/FocusBloc', id: '1' },
    {
      label: 'Opensea',
      link: 'https://opensea.io/collection/focus-bloc-iconic-models-of-the-past',
      id: '2',
    },
    { label: 'Discord', link: 'https://discord.gg/focusbloc', id: '3' },

    {
      label: 'Contact@focusbloc.io',
      link: 'mailto:Contact@focusbloc.io',
      id: '5',
    },
  ];

  if (direction === 'reverse') {
    return (
      <div
        className={` max-sm:w-auto max-sm:items-start flex-col min-[1080px]:flex-row w-full flex gap-2 lg:gap-4 xl:gap-6 md:w-auto ${
          direction === 'reverse'
            ? 'flex-col-reverse min-[1080px]:flex-row-reverse md:flex-row-reverse gap-1 sm:gap-[1.25rem] lg:gap-[4.25rem] xl:gap-20 justify-end xl:justify-start md:pr-0'
            : ''
        }`}
      >
        {rightItems?.map((item, index) => {
          return (
            <NavItem
              key={item.id}
              label={item.label}
              link={item.link}
              hasExternalLink
              itemIndex={index}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex-col min-[1080px]:flex-row w-full flex sm:items-center max-[1080px]:gap-4 gap-2 lg:gap-4 xl:gap-6 md:w-auto">
      <NavItem
        open={open}
        setOpen={setOpen}
        label="Help center"
        link="/help-center"
      />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://mirror.xyz/focusbloc.eth"
        className="block py-2 pl-3 pr-4 h-min max-sm:w-max rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 md:mt-2"
      >
        Blog
      </a>
      <Link
        to="/focus-bloc-pass"
        className=" hidden sm:block py-2 pl-3 pr-4 h-min rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
      >
        <Button
          className="xl:text-xl px-6 text-xl lg:px-8 2xl:px-12 py-[0.1rem] lg:py-8 min-[1080px]:py-[0.45rem] 2xl:py-[0.5rem]"
          isBlack
          label="Focus Bloc Pass"
        />
      </Link>
      <div className=" max-sm:mt-2  flex-row sm:flex-col min-[1080px]:flex-row w-full flex sm:items-center max-[1080px]:gap-4 gap-2 lg:gap-4 xl:gap-6 md:w-auto">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/FocusBloc"
          className="block py-2 pl-3 pr-4 h-min rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
        >
          <img
            alt=""
            className="max-[1080px]:h-10 h-8"
            src="/images/icons/tw.png"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/focusbloc"
          className="block py-2 pl-3 pr-4 h-min rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
        >
          <img
            alt=""
            className="max-[1080px]:h-10 h-8"
            src="/images/icons/ds.png"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/focusbloc/"
          className="block py-2 pl-3 pr-4 h-min rounded md:hover:bg-transparent md:border-0 md:hover:text-brand-grey md:p-0 "
        >
          <img
            alt=""
            className="max-[1080px]:h-10 h-8"
            src="/images/icons/in.png"
          />
        </a>
      </div>

      {/* {rightItems?.map((item, index) => {
        return (
          <NavItem
            key={item.id}
            label={item.label}
            link={item.link}
            hasExternalLink
            itemIndex={index}
          />
        );
      })} */}
    </div>
  );
}

NavItemsExternal.propTypes = {
  direction: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

NavItemsExternal.defaultProps = {
  direction: '',
  open: false,
  setOpen: () => {},
};

export const FAQ_CONTENTS = [
  {
    title: 'What is Focus Bloc ?',
    contentText: `Focus Bloc is the first of its kind vintage photography brand.`,
  },
  {
    title: `What’s an NFT ?`,
    contentText: `NFT stands for “non-fungible token” — a fancy way of saying it’s a unique, one-of-a-kind digital item that users can buy, own, and trade. Some NFTs’ main functions are to be digital art and to look cool; some offer additional utility, like exclusive access to websites or participation in an event. Think of NFTs as rare pieces of art that can also act as a “member’s card”.`,
  },
  {
    title: 'How do I buy my NFT photography ?',
    contentText: `i) You need to be whitelisted – kindly check the twitter account for more information on how to be whitelisted. ii) you need to have a “hot wallet” with 0.5eth + gas fees.`,
  },
  {
    title: 'How many NFTs can I mint ?',
    contentText: `If you are whitelisted you can only mint 1 NFT.`,
  },
  {
    title: 'What sort of rights do I own ?',
    contentText: `Movie Star News grants the NFT rights of the photography. You are granted a personal non-exclusive, nonsublicensable license to view and display the Artwork associated with the NFT solely for the following purposes: (a) for your own personal, non-commercial use; and (b) as part of a marketplace that permits the display, purchase and sale or other transfer of NFTs.`,
  },
];

export const FOCUS_BLOC_PASS_ADVANTAGES_HEADER = {
  title: 'THE FOCUS BLOC PASS ADVANTAGES',
  subTitle: 'And many more to come as we grow our community',
};

export const BLOC_PASS_ADVANTAGES_ITEMS = [
  {
    title: 'Whitelist for next drops and minting 25% discount to public mint',
    content:
      'Focus Bloc will drop new collections on a regular basis (every 4-6 weeks) from iconic and influential photographers who shaped the direction of modern photography',
  },
  {
    title: `Get signed physical prints from the
    photographers`,
    content: `Possibility to request signed printed copies of the photographs
      through our online platform. Available shortly after sell out.`,
  },
  {
    title: `Get access to Focus Bloc
    exclusive content`,
    listContents: [
      { text: 'Private channel access', id: 'advantages-text-1' },
      { text: 'Collaborations', id: 'advantages-text-2' },
      { text: 'Podcasts', id: 'advantages-text-3' },
      { text: 'Events', id: 'advantages-text-4' },
      { text: 'Fairs', id: 'advantages-text-5' },
    ],
  },
];

export const ABOUT_FOCUS_CONTENTS = {
  title: 'ABOUT THE FOCUS BLOC PASS',
  content: `To reward the early supporters of
  Focus Bloc, each mint of the first
  drop “ iconic models of the past”
  by Jean-Claude Deutsch, will be
  allowed to claim the Focus Bloc
  pass.`,
  listText1: `First 30 minters will be
  allowed to claim the Focus
  Bloc pass for free.`,
  listText2: `Last 90 minters will be
  allowed to claim the Focus
  Bloc pass for 0.25eth.`,
};

export const MODELS_COLLECTION = [
  {
    id: 1,
    name: 'Arnold Schwarzenegger',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Arnold+Schwarzenegger.jpg',
  },
  {
    id: 2,
    name: 'Brigitte Bardot',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Brigitte+Bardot.jpg',
  },
  {
    id: 3,
    name: 'Catherine Deneuve',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Catherine+Deneuve.JPG',
  },
  {
    id: 4,
    name: 'Charlotte Rampling',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Charlotte+Rampling.JPG',
  },
  {
    id: 5,
    name: 'Dalida',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Dalida.JPG',
  },
  {
    id: 6,
    name: 'David Bowie',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/David+Bowie.JPG',
  },
  {
    id: 7,
    name: 'Isabelle Adjani',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Isabelle+Adjani.jpg',
  },
  {
    id: 8,
    name: 'Jane Birkin',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Jane+Birkin.JPG',
  },
  {
    id: 9,
    name: 'John Travolta And Gerard Depardieu',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/John+Travolta+and+Gerard+Depardieu.JPG',
  },
  {
    id: 10,
    name: 'Johnny Hallyday',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Johnny+Hallyday.JPG',
  },
  {
    id: 11,
    name: 'Julio Iglesias',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Julio+Iglesias.JPG',
  },
  {
    id: 12,
    name: 'Kirk Douglas',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Kirk+Douglas.JPG',
  },
  {
    id: 13,
    name: 'Luciano Pavarotti',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Luciano+Pavarotti.jpg',
  },
  {
    id: 14,
    name: 'Marie France Pisier',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Marie-France+Pisier.JPG',
  },
  {
    id: 15,
    name: 'Michael Jackson',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Michael+Jackson.JPG',
  },
  {
    id: 16,
    name: 'Romy Schneider',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Romy+Schneider.jpg',
  },
  {
    id: 17,
    name: 'Sade',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Sade.JPG',
  },
  {
    id: 18,
    name: 'Serge Gainsbourg',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Serge+Gainsbourg.JPG',
  },
  {
    id: 19,
    name: 'Sharon Tate',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Sharon+Tate.JPG',
  },
  {
    id: 20,
    name: 'Sophie Marceau',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Sophie+Marceau.JPG',
  },
  {
    id: 21,
    name: 'Sylvester Stallone',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Sylvester+Stallone.JPG',
  },
  {
    id: 22,
    name: 'Ursula Andress',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Ursula+Andress.JPG',
  },
  {
    id: 23,
    name: 'Yves Saint Laurent',
    image:
      'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Yves+Saint+Laurent.JPG',
  },
];

export const TOKEN_DROPDOWN_OPTIONS = [
  { label: 'Token ID 1', id: 1 },
  { label: 'Token ID 2', id: 2 },
  { label: 'Token ID 3', id: 3 },
  { label: 'Token ID 4', id: 4 },
];

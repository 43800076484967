function ImageList() {
  const images = [
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-A-Clockwork-Orange%2C-1971.jpg',
      title: 'A Clockwork Orange, 1971',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Alfred-Hitchcock-on-the-set-of-Psycho%2C-1960.jpg',
      title: 'Alfred Hitchcock on the set of Psycho, 1960',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Audrey-Hepburn.jpg',
      title: 'Audrey Hepburn',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Back-to-the-future-II%2C-Christopher-Lloyd-and-Michael-J.-Fox%2C-1989.jpg',
      title: 'Back to the future II, 1989',
      desc: 'Christopher Lloyd and Michael J. Fox',
    },
    {
      url: "https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Breakfast-at-Tiffany's%2C-Audrey-Hepburn%2C-1961.jpg",
      title: "Breakfast at Tiffany's, 1961",
      desc: 'Audrey Hepburn',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Bruce-Lee.jpg',
      title: 'Enter the Dragon, 1973',
      desc: 'Bruce Lee',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Butch-Cassidy-and-the-kid%2C-Robert-Redford-and-Paul-Newman%2C-1969.jpg',
      title: 'Butch Cassidy and the kid, 1969',
      desc: 'Robert Redford and Paul Newman',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Casablanca%2C-Humphrey-Bogart-and-Ingrid-Bergman%2C-1942.jpg',
      title: 'Casablanca, 1942',
      desc: 'Humphrey Bogart and Ingrid Bergman',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Charlie-Chaplin%2C-1935.jpg',
      title: 'Charlie Chaplin, 1935',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Chinatown%2C-Jack-Nicholson-and-John-Huston%2C-1974.jpg',
      title: 'Chinatown, 1974',
      desc: 'Jack Nicholson and John Huston',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-E.T.-1982.jpg',
      title: 'E.T. 1982',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Easy-Rider%2C-Dennis-Hopper%2C-Peter-Fonda-and-Jack-Nicholson%2C-1969.jpg',
      title: 'Easy Rider, Dennis Hopper, 1969',
      desc: 'Peter Fonda and Jack Nicholson',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Frank-Sinatra-and-Peter-Lawford.jpg',
      title: 'Frank Sinatra and Peter Lawford',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Full-Metal-Jacket%2C-1987.jpg',
      title: 'Full Metal Jacket, 1987',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Goodfellas%2C-Ray-Liotta-and-Lorraine-Bracco%2C-1990.jpg',
      title: 'Goodfellas, 1990',
      desc: 'Ray Liotta and Lorraine Bracco',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Ingrid-Bergman%2C-1941.jpg',
      title: 'Ingrid Bergman, 1941',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-James-Dean%2C-1955.jpg',
      title: 'James Dean, 1955',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Rocky-IV%2C-Sylvester-Stallone%2C-1985.jpg',
      title: 'Rocky IV, 1985',
      desc: 'Sylvester Stallone',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Scarface%2C-Al-Pacino%2C-1983.jpg',
      title: 'Scarface, 1983',
      desc: 'Al Pacino',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Sophia-Loren-at-the-American-Army-headquarters%2C-1954.jpg',
      title: 'Sophia Loren at the American Army headquarters, 1954',
      desc: '',
    },
    // {
    //   url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Star-Wars-IV%2C-Harrison-Ford%2C-Mark-Hamill%2C-Carrie-Fisher%2C-1977.jpg',
    //   title: 'Star Wars IV, 1977',
    //   desc: 'Harrison Ford, Mark Hamill, Carrie Fisher',
    // },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Superman%2C-Christopher-Reeve%2C-1978.jpg',
      title: 'Superman, 1978',
      desc: 'Christopher Reeve',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Swing-Time%2C-Fred-Astaire-and-Ginger-Rogers%2C-1936.jpg',
      title: 'Swing Time, 1936',
      desc: 'Fred Astaire and Ginger Rogers',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-Taxi-Driver%2C-Robert-de-Niro%2C-1976.JPG',
      title: 'Taxi Driver, 1976',
      desc: 'Robert de Niro',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-The-Godfather%2C-Marlon-Brando%2C-1972.jpg',
      title: 'The Godfather, 1972',
      desc: 'Marlon Brando',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-The-Great-Escape%2C-Steve-Mcqueen%2C-1963.jpg',
      title: 'The Great Escape, 1963',
      desc: 'Steve Mcqueen',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-The-Outlaw-Josey-Wales%2C-Clint-Eastwood%2C-1976.jpg',
      title: 'The Outlaw Josey Wales, 1976',
      desc: 'Clint Eastwood',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-The-Seven-Year-Itch%2C-Marilyn-Monroe-and-Tom-Ewell%2C-1955.jpg',
      title: 'The Seven Year Itch, 1955',
      desc: 'Marilyn Monroe and Tom Ewell',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-The-Wild-One%2C-Marlon-Brandon%2C-1953.jpg',
      title: 'The Wild One, Marlon Brandon, 1953',
      desc: '',
    },
    {
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/prev-West-Side-Story%2C-1961.jpg',
      title: 'West Side Story, 1961',
      desc: '',
    },
  ];

  return (
    <div className="my-8 w-full">
      <div className="h-auto grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-8">
        <div className="h-full cols-span-1 gap-8 mt-8 sm:mt-12">
          <div className="w-full h-auto">
            <img
              className="sm:h-[420px] w-auto object-cover"
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-13.jpg"
              alt=""
            />
            <p className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.45rem] 2xl:text-[1.65rem] mt-6">
              The wild one, 1953
            </p>
            <p className="text-description text-lg sm:text-[1.5rem] tracking-tight 2xl:text-[2rem] font-thin">
              Marlon Brando
            </p>
          </div>
        </div>
        <div className="h-full cols-span-1 gap-8 mt-8 sm:mt-12">
          <div className="w-full h-auto">
            <img
              className="sm:h-[420px] w-auto object-cover"
              src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-15.jpg"
              alt=""
            />
            <p className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.45rem] 2xl:text-[1.65rem] mt-6">
              Thelma and Louise, 1983
            </p>
            <p className="text-description text-lg sm:text-[1.5rem] tracking-tight 2xl:text-[2rem] font-thin">
              Susan Sarandon, Geena Davis
            </p>
          </div>
        </div>
        <div className=" h-full w-auto cols-span-1 gap-8 sm:mt-12">
          <div className="">
            <img
              className=" sm:h-[420px] w-auto object-cover"
              src="/images/home/menu1.png"
              alt=""
            />
            <p className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.45rem] 2xl:text-[1.65rem] mt-6">
              Citizen Kane, 1941
            </p>
            <p className="text-description text-lg sm:text-[1.5rem] tracking-tight 2xl:text-[2rem] font-thin">
              Orson Welles
            </p>
          </div>
        </div>
        {images.map(img => {
          return (
            <div
              key={img.title}
              className="h-full cols-span-1 gap-8 mt-8 sm:mt-2"
            >
              <div className="w-full h-auto">
                <img
                  className="sm:h-[420px] w-auto object-cover"
                  src={img.url}
                  alt={img.title}
                />
                <p className="text-heading font-bold text-[1.35rem] sm:text-[1.25rem] lg:text-[1.45rem] 2xl:text-[1.65rem] mt-6">
                  {img.title}
                </p>
                {img.title ? (
                  <p className="text-description text-lg sm:text-[1.5rem] tracking-tight 2xl:text-[2rem] font-thin">
                    {img.desc}
                  </p>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ImageList;

import { useRoutes } from 'react-router-dom';
import BlocPass from 'pages/bloc-pass';
import Home from 'pages/home2';
import Home1 from 'pages/home';
import Board from 'pages/board';
import NoPageFound from 'pages/404';
import CollectionPage from 'pages/collection';
import Layout from 'components/layout';
import RegisterPage from 'pages/register';
import GuidePage from 'pages/guide';
import MintPage from 'pages/mint';
import AllowListChecker from 'pages/allowlist-checker';

function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
    },
    {
      path: '/models',
      element: (
        <Layout>
          <Home1 />
        </Layout>
      ),
    },
    {
      path: '/focus-bloc-pass',
      element: (
        <Layout>
          <BlocPass />
        </Layout>
      ),
    },
    {
      path: '/curation-board',
      element: (
        <Layout>
          <Board />
        </Layout>
      ),
    },
    {
      path: '*',
      element: <NoPageFound />,
    },
    {
      path: '/collection',
      element: <CollectionPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/help-center',
      element: <GuidePage />,
    },
    {
      path: '/mint',
      element: <MintPage />,
    },
    {
      path: 'allowlist-checker',
      element: <AllowListChecker />,
    },
    // {
    //   path: '/guide',
    //   element: <GuidePage />,
    // },
  ]);
}
export default Router;

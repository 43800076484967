import { ParallaxProvider } from 'react-scroll-parallax';
import FocusBlocPassWrapper from './focus-block-pass-wrapper';
import AdvantagesSection from './advantages';
import SelectTokenDropdown from './select-token';

function BlocPass() {
  return (
    <ParallaxProvider>
      <div className="pt-[242px] px-6 md:px-10 bg-brand-black text-brand-white pb-16">
        <FocusBlocPassWrapper />
        <div className="mt-[10%] max-lg:mt-28 sm:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto flex">
          <SelectTokenDropdown />
        </div>
        {/* <div className="mt-10 sm:mt-7 max-md:pb-12 sm:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto flex">
          <ClaimButton />
        </div> */}
        {/* <div className="sm:pl-[10%] mt-[12%]">
          <AboutFocusBlocPass />
        </div> */}
        <AdvantagesSection />
      </div>
    </ParallaxProvider>
  );
}
export default BlocPass;

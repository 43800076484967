function Pass() {
  return (
    <div className=" w-auto mx-auto text-brand-white">
      <div className=" rotate-6 aspect-[2/1] lg:aspect-[15/8] stripes py-12 pl-6 2xl:pl-10 pr-16 max-sm:p-8 shadow-[0px_9px_15px_4px_rgb(255_255_255_/_30%)] border-2 rounded-2xl flex-nowrap flex flex-row items-center gap-8 [@media(min-width:380px)]:gap-12 [@media(min-width:1024px)]:gap-14 max-w-full lg:h-44 2xl:h-auto">
        <img
          src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/brand.png"
          alt="sd"
          className=" shadow-[0px_0px_20px_7px_rgb(255_255_255_/_40%)] max-sm:rounded-tl-[2rem] max-sm:rounded-br-[2rem] rounded-tl-[20px] rounded-br-[20px] w-1/2"
        />
        <p className="font-adelia max-sm:text-[6vw]  text-5xl -ml-4 2xl:ml-0 lg:text-3xl w-auto leading-tight lg:leading-tight -mt-2 lg:mt-4 xl:mt-0">
          FOCUS BLOC PASS
        </p>
      </div>
      <div className="-ml-2 mt-[2.1rem] 2xl:mt-[3rem] -rotate-6 h-24 opacity-20 border-2 rounded-t-3xl border-b-0 shadow-[-10px_-30px_35px_-16px_rgb(255_255_255_/_25%)]max-sm:rounded-tl-[2rem] max-sm:rounded-br-[2rem] rounded-tl-[20px] rounded-br-[20px] w-full">
        <div className="w-[104%] -ml-[2px] h-[110%] bg-[linear-gradient(185deg,rgba(0,0,0,0.0032387955182072714)0%,rgba(0,0,0,0.9948354341736695)75%)] rounded-t-3xl max-sm:rounded-tl-[2rem] max-sm:rounded-br-[2rem] rounded-tl-[20px] rounded-br-[20px]" />
      </div>
    </div>
  );
}

export default Pass;

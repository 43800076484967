import {
  motion,
  // useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
// import useMediaQuery from 'hooks/useMediaQuery';
import { PropTypes } from 'prop-types';
import { useRef } from 'react';

function ImageReveal() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
  });
  return (
    <div
      ref={ref}
      className=" max-sm:pt-[30vh] h-[220vh] sm:h-[200vh] md:h-[300vh] max-md:w-screen"
    >
      <div className="sticky overflow-hidden top-0 ">
        <div className="flex relative justify-center items-center max-sm:pt-12 max-sm:pb-4 sm:py-12">
          <div className="absolute w-10/12 sm:w-8/12 lg:w-5/12 text-center text-description max-sm:leading-[1.22] leading-[0.5] font-lighter text-lg sm:text-2xl lg:text-[1.45rem] 2xl:text-3xl font-thin">
            <span className=" font-bold">
              We are delighted to announce our next drop in partnership with
              Movie Star News.
            </span>
            <br /> We have hand-picked and curated a selection of{' '}
            <span className=" font-bold">333 one-to-one NFT photos</span> of the
            most iconic Hollywood movies and personalities from the{' '}
            <span className=" font-bold">early 1920s to the 1980s</span>. These
            photos represent the pinnacle of the history of cinema and capture
            the essence of this era that has defined film and popular culture
            today.
            <p className="2xl:my-4">
              <br className="2xl:my-16 w-1 h-1 inline-block" />
            </p>{' '}
            For a long time these archives came close to being destroyed
            following the disbandment of the movie studios and the scattering of
            their publicity material. However, despite being passed from one
            company to another, the archives, comprised of original photo
            negatives, have remained intact.&nbsp;
            <span className=" font-bold">
              As of April 2023, fine art collectors and cinema culture
              enthusiasts will finally be able to access these archives.
            </span>{' '}
            The Hollywood Collection represents the heart of the original master
            files from RKO and multiple other studio sources, representing the
            most extensive independent archive of film culture “Movie Star
            News”.
          </div>
          <Image scrollY={scrollYProgress} />
          <Image scrollY={scrollYProgress} isReverse />
        </div>
      </div>
    </div>
  );
}

function Image({ scrollY, isReverse }) {
  // const isBiggerThanSm = useMediaQuery('(min-width: 640px)');
  const rotateTransform = useTransform(
    scrollY,
    [0, 0.5, 1],
    [0, 0, 10 * (isReverse ? 1 : -1)],
  );
  const xTransform = useTransform(
    scrollY,
    [0, 1],
    [isReverse ? '-10%' : '20%', isReverse ? '80%' : '-80%'],
  );
  return (
    <div className="h-[90vh] py-12 w-full sm:max-w-xl 2xl:max-w-3xl">
      <motion.div
        className="w-full sm:max-w-xl 2xl:max-w-3xl bg-center bg-cover h-full sm:rounded-tl-[6rem] rounded-tl-[3rem] sm:rounded-br-[6rem] rounded-br-[3rem] bg-red-400 max-sm:w-[100vw]"
        style={{
          backgroundImage: isReverse
            ? `url(https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-42.png)`
            : `url(https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-41.png)`,
          rotate: rotateTransform,
          x: xTransform,
          boxShadow: '-3px 0px 33px 8px rgba(0, 0, 0, 0.35)',
        }}
      />
    </div>
  );
}

Image.propTypes = {
  scrollY: PropTypes.number.isRequired,
  isReverse: PropTypes.bool,
};

Image.defaultProps = {
  isReverse: false,
};

export default ImageReveal;

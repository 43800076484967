import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Button from 'components/Button';

import ImageCard from './ImageCard';
import ParagraphCard from './ParagraphCard';

function HorizontalScroll() {
  const parentRef = useRef(null);
  const scrollRef = useRef(null);
  // const ref = useRef(null);

  const [width, setWidth] = useState(0);

  const [translateX, setTranslateX] = useState(0);
  // const translateX = useMotionValue(0);
  const widthRef = useRef(null);

  useEffect(() => {
    setWidth(widthRef.current.offsetWidth);
  }, [widthRef]);

  // eslint-disable-next-line no-unused-vars

  const tra = (x, isRev = false) => {
    if (isRev) {
      // if (x < 0) {
      //   return x + window.innerWidth / 4;
      // }
      return 0;
    }
    if (-x < width) {
      return x - window.innerWidth / 4;
    }
    return 0;
  };

  return (
    <div ref={parentRef} className="h-auto">
      <div className="h-[480px] sm:h-screen">
        <div className="relative h-[inherit] overflow-hidden sm:pb-16 max-sm:mb-16 sm:h-[100vh] overflow-x-hidden">
          <div className="absolute top-0 sm:top-6 right-0 sm:pb-8 flex items-center h-full">
            <div className="h-[100%] sm:h-[550px] pt-12 sm:pt-20 pb-12 max-sm:pr-16 sm:px-28 relative">
              <Button
                imgClass="h-4 sm:h-6"
                isBlack
                className=" rounded-full bg-transparent border-brand-white z-10 px-2 sm:px-10 lg:px-4 absolute top-4 sm:top-3"
                onClick={() => setTranslateX(x => tra(x))}
                type="button"
              />
            </div>
          </div>
          <div className="absolute top-0 sm:top-6 left-0 sm:pb-8 flex items-center h-full">
            <div className="h-[100%] sm:h-[550px] pt-12 sm:pt-20 pb-12 max-sm:pl-6 max-sm:w-max sm:px-16 relative">
              <Button
                imgClass="h-4 sm:h-6"
                isBlack
                className=" rounded-full bg-transparent border-brand-white z-10 px-2 sm:px-10 lg:px-4 absolute top-4 max-sm:w-max sm:top-3 rotate-180"
                onClick={() => setTranslateX(x => tra(x, true))}
                type="button"
              />
            </div>
          </div>

          <div className="absolute max-w-screen">
            <motion.div
              // ref={ref}
              ref={widthRef}
              style={{ x: translateX }}
              className=" sm:py-8 flex items-center sm:h-[100vh] min-w-max transition-all duration-300"
            >
              <div
                ref={scrollRef}
                className=" pl-8 pr-20 h-[100%] sm:h-[550px] bg-brand-black text-brand-white flex content-center gap-8 sm:gap-24 pt-20 sm:pt-24 pb-4 sm:pb-8 px-8 sm:px-36 relative"
              >
                <ParagraphCard
                  heading="Movie Star News: The Story of a New York City Icon"
                  text="In the 20th century, before the internet and social media, fans of Hollywood stars relied on printed images to satisfy their fascination with their favorite celebrities. Movie stills and promotional photographs were widely circulated to promote films and were often purchased by fans and collectors. It was during this time, in 1938, that Irving Klaw and his sister Paula Klaw established Movie Star News. The shop was dedicated to the sale of these types of images, catering to the burgeoning fan base of Hollywood stars."
                />

                <ImageCard
                  tra={tra(translateX)}
                  imgRotate={translateX}
                  imageSrc="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-21.jpg"
                />
                <ImageCard
                  tra={tra(translateX)}
                  imgRotate={translateX}
                  imageSrc="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-22.jpg"
                />
                <ParagraphCard
                  text={`Movie Star News had a close relationship
with RKO Pictures, one of the major film
studios of the time. RKO frequently
provided the shop with promotional stills
and posters for their movies, and the
Klaws would use these images to promote
the films in their shop. In return, Movie Star
News would often feature RKO stars
prominently in their window displays,
helping to promote the studio's films to a
wider audience. In 1959, RKO declared
bankruptcy, and Movie Star News'
business changed dramatically.`}
                />

                <ImageCard
                  tra={tra(translateX)}
                  imgRotate={translateX}
                  imageSrc="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-23.jpg"
                />
                <ParagraphCard
                  text={`With no new films to promote, the shop shifted its focus to selling vintage and rare photographs of Hollywood stars from other studios, such as Warner Bros. and 20th Century Fox. This decision proved to be a smart one, as the shop's collection of vintage images became highly sought after by collectors and fans alike.<br/> In 1979, Irving Klaw passed away, and his widow, Paula Klaw, took over the business. However, Paula Klaw was not as passionate about the business as her husband had been, and she struggled to keep the shop afloat. `}
                />

                <ImageCard
                  tra={tra(translateX)}
                  imgRotate={translateX}
                  imageSrc="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-25.jpg"
                />
                <ParagraphCard
                  text={`In 1981, she sold the business to a young couple named Irving and Mary Epstein.<br/>Under the Epsteins' ownership, Movie Star News continued to thrive. They expanded the shop's collection of photographs and opened new locations in New York City and Los Angeles. In 1991, they even published a book featuring some of the shop's most iconic images, titled "Hollywood Pinups." However, with the rise of the internet and the decline of physical media, Movie Star News began to struggle again.`}
                />
                <ParagraphCard text="In 2017, the Epsteins decided to retire and sold the business to a collector and dealer named Eric Caidin. Caidin, who had been a frequent customer of Movie Star News for years, was passionate about preserving the shop and its legacy, and he vowed to keep the shop open for as long as possible. However, in early 2021, Movie Star News announced that it would be closing its doors for good, citing the impact of the COVID-19 pandemic on the business." />
                <ParagraphCard text="The closure of Movie Star News marks the end of an era in New York City. For over 80 years, the shop was a fixture of midtown Manhattan, attracting fans and collectors from around the world. Its extensive collection of Hollywood photographs, particularly its pin-up images, helped to define popular culture in the 20th century, and its influence can still be felt today." />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalScroll;

import PropTypes from 'prop-types';
import Avatar from 'pages/board/Panel/Avatar';
import InstagramIcon from 'pages/board/Panel/InstagramIcon';
import TwitterIcon from 'pages/board/Panel/TwitterIcon';
import { useState } from 'react';
import classNames from 'classnames';

function Profile({
  name,
  profession,
  workplaces,
  aboutPara,
  linkType,
  linkTo,
  imageLink,
}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="text-center text-brand-white">
      <div className="flex items-center justify-center w-auto h-auto mb-6">
        <Avatar imageLink={imageLink} />
      </div>
      <h3 className="mb-3 font-semibold leading-tight text-4xl">{name}</h3>
      <p className="mb-3 font-semibold leading-tight text-2xl">{profession}</p>
      <p className="mb-4 font-semibold leading-tight tracking-wide text-[1.35rem] italic">
        {workplaces}
      </p>
      <p className="mb-3 leading-tight tracking-normal text-[1.3rem] opacity-75">
        {!isOpen ? aboutPara.split(' ').splice(0, 11).join(' ') : aboutPara}
      </p>
      <button
        type="button"
        onClick={() => {
          setOpen(op => !op);
        }}
        className="text-center w-full hover:cursor-pointer mb-1"
      >
        <p className=" -mb-2 font-semibold leading-tight text-[1.35rem]">
          View {isOpen ? 'less' : 'more'}
        </p>
        <svg
          className={classNames(
            ' fill-brand-white mx-auto opacity-60 transition-transform',
            isOpen && 'rotate-180',
          )}
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          width="48"
        >
          <path d="M24 29 13.8 18.8l.8-.8 9.4 9.4 9.4-9.4.8.8Z" />
        </svg>
      </button>
      <div className="text-center w-full ">
        <div className="mx-auto w-min mb-2">
          {linkType === 'twitter' ? <TwitterIcon /> : <InstagramIcon />}
        </div>
        <a
          className="leading-tight tracking-normal text-[1.3rem] text-brand-white break-words"
          href={linkTo}
        >
          {linkTo}
        </a>
      </div>
    </div>
  );
}

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  workplaces: PropTypes.string.isRequired,
  aboutPara: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  imageLink: PropTypes.string.isRequired,
};

export default Profile;

import Panel from 'pages/board/Panel';

function Board() {
  return (
    <div className="pt-[298px] pb-16 bg-brand-black">
      <Panel />
    </div>
  );
}

export default Board;

import useTextAnimation from 'hooks/useTextAnimation';
import PropTypes from 'prop-types';

function AdvantagesGridItemView({
  title,
  content,
  hasListContent,
  listContents,
  titleId,
  paragraphId,
}) {
  useTextAnimation(titleId);
  useTextAnimation(paragraphId);
  useTextAnimation(`border-${titleId}`);

  const renderListAnimationContent = (id, text) => {
    useTextAnimation(id);
    useTextAnimation(`${id}-dot`);

    return (
      <div className="flex" key={id}>
        <div className="h-5 w-5 rounded-full text-3xl" id={`${id}-dot`}>
          •
        </div>
        <li className="text-3xl font-bold ml-[4%]" key={id} id={id}>
          {text}
        </li>
      </div>
    );
  };

  const renderListView = () => {
    return (
      <ul className="mt-[5%] ml-[5%] sm:ml-[2.5%] list-none">
        {listContents.map(({ text, id }) =>
          renderListAnimationContent(id, text),
        )}
      </ul>
    );
  };

  const renderNormalText = () => (
    <p className="mt-8 text-3xl font-semibold" id={paragraphId}>
      {content}
    </p>
  );

  return (
    <div className="overflow-hidden">
      <h2
        className="text-4xl sm:text-[52px] font-bold leading-none mt-[1%]"
        id={titleId}
      >
        {title}
      </h2>
      {hasListContent ? renderListView() : renderNormalText()}
      <div
        className="tracking-normal text-3xl font-bold"
        id={`border-${titleId}`}
      >
        _________________________________________________
      </div>
      {/* <hr className="mt-10 w-[90%] h-[2px]" color="white" /> */}
    </div>
  );
}

export default AdvantagesGridItemView;

AdvantagesGridItemView.defaultProps = {
  title: '',
  content: '',
  hasListContent: false,
  listContents: [],
};

AdvantagesGridItemView.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  hasListContent: PropTypes.bool,
  listContents: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  titleId: PropTypes.string.isRequired,
  paragraphId: PropTypes.string.isRequired,
};

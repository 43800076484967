/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Button from 'components/Button';
import RainbowKitButton from 'components/RainbowKitButton';
import PropTypes from 'prop-types';
import proofs from 'proofs/proofs';
import { useAccount, useProvider, useSigner } from 'wagmi';
import { Contract } from 'ethers';
import config from 'contracts/config';
import focusBlocHollywoodABI from 'contracts/focusBlocHollywoodABI';
import genesisPassABI from 'contracts/genesisPassABI';
import toast from 'react-hot-toast';

function MintPage() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 sm:h-screen w-screen bg-brand-black text-brand-white min-h-screen">
      <div
        className="min-h-screen h-full w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(/images/mint.jpg)`,
        }}
      >
        <div className="min-h-screen max-sm:py-12  max-md:py-6 w-screen sm:h-screen md:w-full md:h-full backdrop-brightness-[0.3]  md:backdrop-brightness-[0.45] text-center flex max-md:flex-col items-center">
          <div className="w-full">
            <p className="font-bradlens text-[3.25rem] md:text-[5rem] leading-none">
              hollywood
            </p>
            <p className="font-bradlens text-[1.5rem] md:text-3xl mt-2 md:mt-4 md:mb-8 tracking-normal leading-none">
              from Golden Age to the first Blockbusters
            </p>
            <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-normal 2xl:text-[1.75rem] sm:px-28 max-sm:my-6 max-md:my-4">
              333 images featuring the most recognised movies and icons of
              Hollywood.
            </p>
          </div>

          <div className="md:hidden w-full h-full px-4 sm:px-8 text-brand-white text-center flex justify-center flex-wrap content-around">
            <div className=" w-full sm:px-16 grid grid-cols-1 max-sm:gap-16 justify-between content-between h-full text-left">
              {/* <CheckEth /> */}

              {/* <MintSuccess /> */}
              {/* <ConnectSection /> */}
              <div>
                <p className=" text-brand-white text-center mt-4">
                  Thanks for your support so far! <br />
                  Allowlist still going for 24 hours after which we will reveal
                  and start the public phase with the remaining of the supply on
                  Opensea at 0.5eth 📸
                </p>
                <MintingSection />
              </div>

              {/* mint button */}
              {/* <Button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                label="Mint"
                className="w-full px-6 lg:px-12 2xl:px-16 my-4 text-lg xl:text-2xl 2xl:text-3xl py-[0.5rem] lg:py-[0.5rem] 2xl:py-[0.5rem] md:h-20 font-semibold"
                labelClass="w-full"
              /> */}

              {/* <DropDownSection />
              <FinalMessage /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="max-md:hidden h-full px-8 md:px-24 text-center flex flex-col flex-wrap justify-start gap-16 py-16 content-around">
        <div className="w-full">
          <div className="w-full flex justify-center">
            <a href="./" className="-mb-4">
              <img
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Focus+Bloc+logo.png"
                className="w-[12rem] md:w-[15rem] 2xl:w-[20rem]"
                alt="focus bloc logo"
              />
            </a>
          </div>
          {/* <div className="text-3xl px-1 tracking-tight leading-none h-min w-full flex items-center flex-wrap justify-center">
              Mint opens in &nbsp; <Counter />
            </div> */}
        </div>

        <div className="w-full">
          <p className=" text-brand-white max-sm:text-sm">
            Thanks for your support so far! <br />
            Allowlist still going for 24 hours after which we will reveal and
            start the public phase with the remaining of the supply on Opensea
            at 0.5eth 📸
          </p>
          {/* <CheckEth /> */}
          <MintingSection />

          {/* <DropDownSection /> */}
          {/* <FinalMessage /> */}
        </div>
      </div>
    </div>
  );
}

function InputField({ label, name, type, id, required }) {
  return (
    <label htmlFor="mce-EMAIL" className="h-12 md:h-[3.4rem] w-full">
      {/* Email Address <span className="asterisk">*</span> */}
      <p className="pl-6  text-left text-[1.25rem] sm:text-[1.35rem] md:text-[1.5rem] font-semibold">
        {label}
      </p>
      <input
        required={required}
        type={type}
        name={name}
        className=" text-left w-full md:h-full tracking-tight max-sm:py-2 bg-transparent border border-brand-white sm:tracking-wider text-[1.5rem] sm:text-2xl 2xl:text-3xl block flex-grow-0 max-md:text-center md:pl-8 font-founders rounded-full"
        id={id}
      />
    </label>
  );
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

function CheckEth() {
  const [ethAddress, setEthAddress] = React.useState('');
  const [whitelisted, setWhitelisted] = React.useState(false);
  const checkWallet = async address => {
    const exists = proofs.wallets.find(
      proof => proof.address.toLowerCase() === address.toLowerCase(),
    );

    if (exists) {
      setWhitelisted(true);
    } else {
      setWhitelisted(false);
    }
  };
  return (
    <div className="max-md:mt-12">
      <p className="text-[1.35rem] md:text-3xl px-1 py-4 tracking-tight leading-none h-min">
        Check if you’re on the Allowlist
      </p>
      <div className="w-full h-auto">
        <label htmlFor="mce-EMAIL" className="h-auto md:h-20 block">
          <input
            type="text"
            // defaultValue
            placeholder="Enter your ETH address"
            name="EMAIL"
            onChange={e => {
              setEthAddress(e.target.value);
              checkWallet(e.target.value);
            }}
            // className="required email"
            className="text-brand-white h-full w-full tracking-tight sm:tracking-wider max-md:py-[1rem] text-lg xl:text-2xl 2xl:text-3xl text-center bg-brand-grey  block flex-grow-0 max-md:text-center font-founders md:pt-4 rounded-full"
            id="mce-EMAIL"
            required
          />
        </label>

        <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
      </div>
      <br />
      {ethAddress && <MintSuccess isWhitelisted={whitelisted} />}
    </div>
  );
}

function MintSuccess({ isWhitelisted }) {
  return (
    <div className="max-md:mt-8 max-md:text-center">
      {isWhitelisted === true ? (
        <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem]">
          You&apos;re successfully registered on the Allowlist
        </p>
      ) : (
        <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem] px-1 py-4 h-min">
          You&apos;re not registered on the Allowlist, please contact us at{' '}
          <a
            href="mailto:contact@focusbloc.io"
            className="underline text-blue-700"
          >
            contact@focusbloc.io
          </a>
        </p>
      )}
    </div>
  );
}

function MintingSection() {
  const { address } = useAccount();
  const { data: signer, isError, isLoading } = useSigner();
  const [maxMint, setMaxMint] = React.useState(2);
  const [amount, setAmount] = React.useState(1);
  const [isUserAllowed, setIsUserAllowed] = React.useState(false);
  const [txHash, setTxHash] = React.useState('');

  const getMaxMintableAmount = async () => {
    const focusBlocHollywood = new Contract(
      config.focus_bloc_hollywood_address,
      focusBlocHollywoodABI,
      signer,
    );

    const genesisPass = new Contract(
      config.genesis_pass_address,
      genesisPassABI,
      signer,
    );

    const genesisBalance = await genesisPass.balanceOf(address);
    const focusBlocBalance = await focusBlocHollywood.balanceOf(address);
    const maxMintableAmount =
      genesisBalance.toNumber() > 0
        ? genesisBalance.toNumber() * 2 - focusBlocBalance.toNumber()
        : 2 - focusBlocBalance.toNumber();
    setMaxMint(maxMintableAmount);
  };

  const mint = async () => {
    setTxHash('');
    const NULL_PROOF = [
      '0x0000000000000000000000000000000000000000000000000000000000000000',
    ];

    const focusBlocHollywood = new Contract(
      config.focus_bloc_hollywood_address,
      focusBlocHollywoodABI,
      signer,
    );

    const cost = await focusBlocHollywood.getCost(address, amount);

    const { wallets } = proofs;

    const userProof =
      wallets.find(
        proof => proof.address.toLowerCase() === address.toLowerCase(),
      )?.proof || NULL_PROOF;

    try {
      const tx = await focusBlocHollywood.mint(userProof, amount, {
        value: cost,
      });

      toast.promise(tx.wait(), {
        loading: 'Minting NFT',
        success: receipt => {
          setTxHash(receipt.transactionHash);
          return 'NFT Minted';
        },
        error: 'Error Minting NFT',
      });
    } catch (e) {
      toast.error(e.reason);
    }
  };

  const checkIfAllowed = async () => {
    let isAllowed = false;
    const genesisPass = new Contract(
      config.genesis_pass_address,
      genesisPassABI,
      signer,
    );

    const focusBlocHollywood = new Contract(
      config.focus_bloc_hollywood_address,
      focusBlocHollywoodABI,
      signer,
    );

    const isWhitelistActive = await focusBlocHollywood.whiteListActive();
    if (!isWhitelistActive) return true;

    const isWhitelisted = proofs.wallets.find(
      proof => proof.address.toLowerCase() === address.toLowerCase(),
    );

    if (!isWhitelisted) {
      const balance = await genesisPass.balanceOf(address);
      isAllowed = balance.toNumber() > 0;
    } else {
      isAllowed = true;
    }

    return isAllowed;
  };

  useEffect(() => {
    if (address) {
      checkIfAllowed().then(isAllowed => {
        setIsUserAllowed(isAllowed);
      });
    }
  }, [address, signer]);

  useEffect(() => {
    if (address) {
      getMaxMintableAmount();
    }
  }, [address, signer]);

  return (
    <div>
      {!address ? (
        <RainbowKitButton />
      ) : (
        <div>
          {isUserAllowed ? (
            <div>
              {!txHash ? (
                <div className="flex flex-col mt-4 gap-y-8">
                  <div className=" text-brand-white w-full sm:tracking-wider text-lg xl:text-[1.2rem] 2xl:text-2xl text-center bg-brand-grey  flex-grow-0 max-md:text-center font-founders rounded-full grid grid-cols-3 divide-x divide-brand-greyLight">
                    <p className="leading-[1] py-4 px-4 tracking-normal font-semibold align-middle text-sm">
                      <span className="font-bold text-2xl">2</span> <br /> per
                      WL / Genesis Pass
                    </p>
                    <p className="leading-[1] py-4 px-4 tracking-normal font-semibold align-middle text-sm">
                      <span className="font-bold text-2xl">0.4</span> <br /> eth
                      for whitelisted
                    </p>
                    <p className="leading-[1] py-4 px-4 tracking-normal font-semibold align-middle text-sm">
                      <span className="font-bold text-2xl">0.3</span> <br /> for
                      pass holders
                    </p>
                  </div>
                  {maxMint > 0 ? (
                    <>
                      <p className="text-center">
                        You can mint up to{' '}
                        <span className="font-bold">{maxMint}</span> NFTs
                      </p>
                      <div
                        className=" bg-brand-black text-center 
          rounded-full border-2 border-brand-white w-full min-h-[40px] md:min-h-[84px] 
          truncate flex items-center justify-between px-8"
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (amount > 1) setAmount(amount - 1);
                          }}
                        >
                          -
                        </button>
                        {amount}
                        <button
                          type="button"
                          onClick={() => {
                            if (amount < maxMint) setAmount(amount + 1);
                            else
                              toast.error(
                                `You can only mint up to ${maxMint} NFTs`,
                              );
                          }}
                          className="text-brand-white"
                        >
                          +
                        </button>
                      </div>
                      <Button
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        label="Mint"
                        className="w-full px-6 lg:px-12 2xl:px-16 text-lg xl:text-2xl 2xl:text-3xl py-[0.5rem] lg:py-[0.5rem] 2xl:py-[0.5rem] md:h-20 font-semibold"
                        labelClass="w-full"
                        onClick={mint}
                      />
                    </>
                  ) : (
                    <p className="text-center">
                      You have minted all your available NFTs
                    </p>
                  )}
                </div>
              ) : (
                <div>{txHash && <FinalMessage txHash={txHash} />}</div>
              )}
            </div>
          ) : (
            <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem] px-1 py-4 h-min">
              You&apos;re not registered on the Allowlist and do not hold any
              Focus Bloc Genesis Pass, please contact us at{' '}
              <a
                href="mailto:contact@focusbloc.io"
                className="underline text-blue-700"
              >
                contact@focusbloc.io
              </a>
            </p>
          )}
        </div>
      )}
    </div>
  );
}

function FinalMessage({ txHash }) {
  return (
    <div className="max-md:mt-16 max-md:text-center">
      <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem]">
        You have successfully minted your NFT from Hollywood : from Golden Age
        to the first Blockbusters
      </p>
      <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem] px-1 py-4 h-min">
        Check your transaction on{' '}
        <a
          href={`https://etherscan.io/tx/${txHash}`}
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-700"
        >
          Etherscan
        </a>
      </p>
    </div>
  );
}

export default MintPage;

import Button from 'components/Button';
import { useScroll, useTransform, motion } from 'framer-motion';
import useMediaQuery from 'hooks/useMediaQuery';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import Images from '../Images';

function ModelSection() {
  const parentRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: parentRef });
  const isBiggerThanSm = useMediaQuery('(min-width: 640px)');
  const y1 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, isBiggerThanSm ? -60 : 0],
  );
  const y2 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, isBiggerThanSm ? -150 : 0],
  );
  const y3 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, isBiggerThanSm ? -204 : 0],
  );
  const y4 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, isBiggerThanSm ? -160 : 0],
  );
  const y5 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, isBiggerThanSm ? -140 : 0],
  );

  return (
    <div
      ref={parentRef}
      className="w-full bg-brand-white pt-12 2xl:py-20 border-b-[3px] border-black"
    >
      <div className="text-center px-8 md:px-20">
        <p className=" text-heading mb-4 text-[1.8rem] lg:text-[2rem] 2xl:text-[2.25rem]">
          &ldquo;Iconic models of the past&rdquo;
        </p>
        <p className="text-description text-[1.35rem] sm:text-[1.75rem] tracking-tight 2xl:text-[2rem]">
          Focus Bloc and Paris Match have collaborated for their first
          collection, curating 120 iconic photos featuring 23 celebrities.
        </p>
      </div>
      {isBiggerThanSm ? (
        <div className=" grid md:grid-cols-3 md:h-[100vh] xl:h-[120vh] sm:gap-8 sm:px-12 xl:px-32 mt-16">
          <div className=" max-sm:px-2 md:max-h-[80vh] xl:max-h-[100vh] mt-12 md:w-[32vw] gap-8 sm:gap-16 grid max-sm:grid-cols-1 max-md:grid-cols-2 md:grid-rows-2">
            <motion.div style={{ y: y1 }} className="md:h-full">
              <img
                className=" md:h-auto md:w-auto object-contain"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-Yves-Saint-Laurent.JPG"
                alt="Brigitte-Bardot"
              />
              <p className="img-label mt-2 ml-1">
                Yves Saint-Laurent, Marrakech, 1983
              </p>
            </motion.div>
            <motion.div style={{ y: y2 }} className=" max-sm:mb-8 md:h-full">
              <img
                className="md:h-full md:w-auto object-contain"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-2.jpg"
                alt="Brigitte-Bardot"
              />
              <p className="img-label mt-2 ml-1">David Bowie, Paris, 1977</p>
            </motion.div>
          </div>
          <div className=" max-sm:px-2 md:max-h-[100vh] 2xl:max-h-[120vh] md:flex md:flex-col gap-8 lg:gap-0 2xl:gap-16 md:self-end md:items-center md:max-2xl:pb-20">
            <motion.div
              style={{ y: y3 }}
              className="md:h-full md:w-10/12 lg:w-10/12 xl:w-3/4 max-sm:mb-10"
            >
              <img
                className="h-auto w-full mx-auto object-contain"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-5.jpg"
                alt="Brigitte-Bardot"
              />
              <p className="img-label mt-2 ml-1">Sharon Tate, Paris 1968</p>
            </motion.div>
            <div className="hidden md:block w-11/12">
              <a href="https://opensea.io/collection/focus-bloc-iconic-models-of-the-past">
                <Button
                  isBlack
                  className="w-full my-3 px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl tracking-normal"
                  label="Buy on Opensea"
                />
              </a>

              <Link to="/models">
                <Button
                  className="w-full bg-brand-white hover:bg-brand-black px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl tracking-normal"
                  label="Learn more"
                />
              </Link>
            </div>
          </div>

          <div className="max-sm:px-2 md:max-h-[100vh] xl:max-h-[120vh] sm:gap-12 grid max-sm:grid-cols-1 max-md:grid-cols-2 md:grid-rows-2 w-auto">
            <motion.div style={{ y: y4 }} className="h-5/6">
              <img
                className="h-full w-full sm:w-auto object-contain"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-4.jpg"
                alt="Brigitte-Bardot"
              />
              <p className="img-label mt-2 ml-1">
                Serge Gainsbourg and Jane Birkin, Paris, 1971
              </p>
            </motion.div>
            <motion.div style={{ y: y5 }} className="h-5/6 sm:ml-auto">
              <img
                className="h-full w-full sm:w-auto object-contain"
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-3.jpg"
                alt="Brigitte-Bardot"
              />
              <p className="img-label mt-2 ml-1">
                Brigitte Bardot, Train Mistral, 1969
              </p>
            </motion.div>
          </div>
          <div className="md:hidden max-sm:px-2 mx-auto sm:w-72">
            <a href="https://opensea.io/collection/focus-bloc-iconic-models-of-the-past">
              <Button
                isBlack
                className="mb-4 lg:text-2xl w-auto px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-x max-sm:w-[260px]"
                label="Buy on Opensea"
              />
            </a>
            <Link to="/models">
              <Button
                className="sm:w-full bg-brand-white text-brand-black mb-4 lg:text-2xl mx-auto px-6 lg:px-8 2xl:px-12 py-[0.4rem] lg:py-[0.45rem] 2xl:py-[1rem] xl:text-xl max-sm:w-[260px]"
                label="Learn more"
              />
            </Link>
          </div>
        </div>
      ) : (
        <MobileCarousel />
      )}
    </div>
  );
}

function MobileCarousel() {
  const imagesArr = [
    {
      label: 'monroe',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-Yves-Saint-Laurent.JPG',
    },
    {
      label: 'charlie chaplin',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-2.jpg',
    },
    {
      label: '',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-5.jpg',
    },
    {
      label: 'monroe',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-4.jpg',
    },
    {
      label: 'charlie chaplin',
      url: 'https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/sample-3.jpg',
    },
  ];
  return (
    <div className=" -mx-8">
      <Images imagesData={imagesArr} isModels />
    </div>
  );
}

export default ModelSection;

import { heroContent } from 'content/home';

function Hero() {
  return (
    <div className="mb-8 md:mx-4 lg:mx-8 xl:mx-16 bg-cover md:rounded-3xl h-[130vh] hero-bg-mobile md:hero-bg bg-center">
      <div className="h-full md:backdrop-brightness-[0.55] md:rounded-3xl grid items-end relative">
        <div className="font-adelia px-8 md:px-16 lg:pl-20 lg:pr-[22rem] py-10 [@media(max-width:425px)]:text-[2.1rem] text-[2.65rem] tracking-normal lg:text-6xl leading-[1.3] lg:leading-[1.25] bottom-0 absolute">
          {heroContent.text}
        </div>
      </div>
    </div>
  );
}

export default Hero;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import proofs from 'proofs/proofs';

function AllowListChecker() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 sm:h-screen w-screen bg-brand-black text-brand-white min-h-screen">
      <div
        className="min-h-screen h-full w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(/images/mint.jpg)`,
        }}
      >
        <div className="min-h-screen max-sm:py-12  max-md:py-6 w-screen sm:h-screen md:w-full md:h-full backdrop-brightness-[0.3]  md:backdrop-brightness-[0.45] text-center flex max-md:flex-col items-center">
          <div className="w-full">
            <p className="font-bradlens text-[3.25rem] md:text-[5rem] leading-none">
              hollywood
            </p>
            <p className="font-bradlens text-[1.5rem] md:text-3xl mt-2 md:mt-4 md:mb-8 tracking-normal leading-none">
              from Golden Age to the first Blockbusters
            </p>
            <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-normal 2xl:text-[1.75rem] sm:px-28 max-sm:my-6 max-md:my-4">
              333 images featuring the most recognised movies and icons of
              Hollywood.
            </p>
          </div>

          <p className="md:hidden font-bradlens text-[1.5rem] md:text-3xl tracking-normal leading-none h-min mt-10 mb-8 sm:mt-6 sm:mb-4 max-sm:px-4">
            Check if you’re on the Allowlist
          </p>
          <div className="md:hidden w-full h-full px-4 sm:px-8 text-brand-white text-center flex justify-center flex-wrap content-around">
            <div className=" w-full sm:px-16 grid grid-cols-1 max-sm:gap-16 justify-between content-between h-full text-left">
              <CheckEth />
            </div>
          </div>
        </div>
      </div>
      <div className="max-md:hidden h-full px-8 md:px-24 text-center flex flex-col flex-wrap justify-start gap-16 py-16 content-around">
        <div className="w-full">
          <div className="w-full flex justify-center">
            <a href="./" className="-mb-4">
              <img
                src="https://focusbloc.s3.eu-west-3.amazonaws.com/focusbloc-images/Focus+Bloc+logo.png"
                className="w-[12rem] md:w-[15rem] 2xl:w-[20rem]"
                alt="focus bloc logo"
              />
            </a>
          </div>
        </div>
        <p className="hidden md:block font-bradlens text-[1.5rem] md:text-3xl tracking-normal leading-none h-min mt-10 mb-8 sm:mt-6 sm:mb-4 max-sm:px-4">
          Check if you’re on the Allowlist
        </p>
        <div className="w-full">
          <CheckEth />
        </div>
      </div>
    </div>
  );
}

function InputField({ label, name, type, id, required }) {
  return (
    <label htmlFor="mce-EMAIL" className="h-12 md:h-[3.4rem] w-full">
      {/* Email Address <span className="asterisk">*</span> */}
      <p className="pl-6  text-left text-[1.25rem] sm:text-[1.35rem] md:text-[1.5rem] font-semibold">
        {label}
      </p>
      <input
        required={required}
        type={type}
        name={name}
        className=" text-left w-full md:h-full tracking-tight max-sm:py-2 bg-transparent border border-brand-white sm:tracking-wider text-[1.5rem] sm:text-2xl 2xl:text-3xl block flex-grow-0 max-md:text-center md:pl-8 font-founders rounded-full"
        id={id}
      />
    </label>
  );
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

function CheckEth() {
  const [ethAddress, setEthAddress] = React.useState('');
  const [whitelisted, setWhitelisted] = React.useState(false);
  const checkWallet = async address => {
    const exists = proofs.wallets.find(
      proof => proof.address.toLowerCase() === address.toLowerCase(),
    );

    if (exists) {
      setWhitelisted(true);
    } else {
      setWhitelisted(false);
    }
  };
  return (
    <div className="max-md:mt-12">
      <div className="w-full h-auto">
        <label htmlFor="mce-EMAIL" className="h-auto md:h-20 block">
          <input
            type="text"
            // defaultValue
            placeholder="Enter your ETH address"
            name="EMAIL"
            onChange={e => {
              setEthAddress(e.target.value);
              checkWallet(e.target.value);
            }}
            // className="required email"
            className="text-brand-white h-full w-full tracking-tight sm:tracking-wider max-md:py-[1rem]
             text-md sm:text-xl text-center bg-brand-grey  block flex-grow-0 max-md:text-center
              font-founders md:pt-4 rounded-full"
            id="mce-EMAIL"
            required
          />
        </label>

        <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
      </div>
      <br />
      {ethAddress && <MintSuccess isWhitelisted={whitelisted} />}
    </div>
  );
}

function MintSuccess({ isWhitelisted }) {
  return (
    <div className="max-md:mt-8 max-md:text-center">
      {isWhitelisted === true ? (
        <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem]">
          You&apos;re successfully registered on the Allowlist
        </p>
      ) : (
        <p className="text-[1.35rem] md:text-[1.65rem] font-semibold leading-[1.2] tracking-wide 2xl:text-[1.75rem] px-1 py-4 h-min">
          You&apos;re not registered on the Allowlist, please contact us at{' '}
          <a
            href="mailto:contact@focusbloc.io"
            className="underline text-blue-700"
          >
            contact@focusbloc.io
          </a>
        </p>
      )}
    </div>
  );
}

export default AllowListChecker;

function MailChimpForm() {
  return (
    <div id="mc_embed_signup" className="w-full">
      <form
        action="https://focusbloc.us21.list-manage.com/subscribe/post?u=2d4545a41ff669f4ddca3f53c&id=44565cb2fa&f_id=00c6d2e1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate w-full"
        target="_self"
      >
        <div
          id="mc_embed_signup_scroll"
          className="flex w-auto flex-col md:flex-row max-md:max-w-md max-md:mx-auto max-sm:h-32  max-md:h-56 h-[6rem] justify-center lg:h-[5rem] 2xl:h-[6.75rem]"
        >
          {/* <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div> */}
          <div className="mc-field-group h-full w-full max-w-[40rem] ">
            <label htmlFor="mce-EMAIL" className="h-auto md:h-20 w-full">
              {/* Email Address <span className="asterisk">*</span> */}
              <input
                type="email"
                // defaultValue
                placeholder="Enter email address"
                name="EMAIL"
                // className="required email"
                className=" w-full text-brand-white h-full tracking-tight sm:tracking-wider text-[1.25rem] sm:text-[1.75rem] 2xl:text-4xl bg-brand-grey  block flex-grow-0 max-md:text-center md:pl-8 font-founders pt-4 rounded-full"
                id="mce-EMAIL"
                required
              />
            </label>

            <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
          </div>{' '}
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_2d4545a41ff669f4ddca3f53c_44565cb2fa"
              tabIndex={-1}
              // defaultValue
            />
          </div>
          {/* className="clear"  */}
          <div className="h-full">
            {/* <input
              value="Receive update"
              type="submit"
              // defaultValue="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button text-3xl md:text-[2rem] pt-4 sm:pt-4 text-center px-2 sm:px-6 bg-brand-white hover:cursor-pointer text-brand-black whitespace-normal lg:w-[16rem] md:flex-grow-0 font-semibold h-full w-full font-founders rounded-full"
            /> */}
            <button
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="text-brand-black border-2 h-full md:-ml-1 flex items-center justify-center gap-6 w-full md:w-auto px-8 lg:px-10 md:py-[2.25rem] border-black bg-brand-offWhite text-3xl 2xl:text-4xl leading-none font-founders text-center transition duration-300 ease-in-out rounded-full"
            >
              <p className="pt-2 font-medium text-[1.25rem] sm:text-[1.75rem]">
                Receive update
              </p>
              <img
                className=" h-10 sm:h-12"
                src="/images/right-arrow.png"
                alt="arrow-icon"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MailChimpForm;
